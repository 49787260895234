import {AppStateType} from "../store";

export const getOrdersInfo = (state: AppStateType) => {
    return state.adminPanelData.orders
}
export const getOrdersTotalCount = (state: AppStateType) => {
    return state.adminPanelData.totalCount
}
export const getCurrentSettingsArray = (state: AppStateType) => {
    return state.adminPanelData.settings
}