import {loaderActions} from "../reducers/loaderReducer";
import {adminService} from "../../api/adminService";
import {ResponseStatuses} from "../../api/axiosInstance";
import {NotificationError} from "../../utils/notifications";
import {adminPanelActions, ThunkType} from "../reducers/admin/adminPanelReducer";

export const ordersFilter = (statuses: string[], dateFrom: string, dateTo: string, orderNumber: string, pageSize: number = 10, pageNumber: number = 1,sortBy: string = 'Number', sortType: string = 'Ascending'): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(true))

            let data = await adminService.filterOrders(statuses, dateFrom, dateTo, orderNumber, pageSize, pageNumber, sortBy, sortType);

            if (data.status === ResponseStatuses.SUCCESS) {
                dispatch(adminPanelActions.setOrdersInfo(data.data));
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(false))
        }
    }