export enum MediaTypes {
    TEXT = 'Text',
    PHOTO = 'Photo',
    VIDEO = 'Video',
    GIF = 'Gif',
}

export enum FilesLimitations {
    PHOTO_LIMITATION = 10,
    VIDEO_LIMITATION = 1,
    GIF_LIMITATION = 5,
}