import axiosInstance from "./axiosInstance";

export const riddleService = {
    getPredefinedRiddles() {
        return axiosInstance.get(`api/riddles/predefined`)
            .then(res => res.data);
    },
    addRiddle(orderId: string, question: string, answer: string) {
        return axiosInstance.post(`api/orders/${orderId}/riddle`, {question, answer})
            .then(res => res.data);
    },
    addPredefinedRiddleToOrder(orderId: string, riddleId: string) {
        return axiosInstance.post(`/api/orders/${orderId}/riddle/${riddleId}`)
            .then(res => res.data);
    },
    deleteRiddle(orderId: string, riddleId: string) {
        return axiosInstance.delete(`/api/orders/${orderId}/riddle/${riddleId}`)
            .then(res => res.data);
    },
}
