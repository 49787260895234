import React, {useState} from 'react';
import {Button} from "antd";
import styles from "./CameraOpen.module.scss";
import Webcam from "react-webcam";
import {CameraOutlined, CloseOutlined, LoadingOutlined, RetweetOutlined} from "@ant-design/icons";
import useWindowDimensions from "../../../../helpers/getWindowDimensions";
import {v1 as uuidv1} from "uuid";
import {dataUrlToFile} from "../../../../helpers/dataUrlToFile";


type PhotoProps = {
    videoConstraints: any;
    isTakePhotoModalVisible: boolean;
    setIsTakePhotoModalVisible: (isVisible: boolean) => void;
    onDrop: (files: File[]) => void;
}

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";


export const Photo: React.FC<PhotoProps> = ({isTakePhotoModalVisible, setIsTakePhotoModalVisible, onDrop, videoConstraints}) => {

    const webcamRef: any = React.useRef(null)

    const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
    const [devices, setDevices] = React.useState([] as MediaDeviceInfo[]);


    const capture = async () => {
        if (webcamRef) {
            const imageSrc = webcamRef.current.getScreenshot();

            //converting base64 to .jpeg file
            const filename = `photo_${uuidv1()}.jpeg`
            const file = dataUrlToFile(imageSrc, filename);

            onDrop([file])
        }
    }

    const handleSwitchCameraClick = React.useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);

    const [isReady, setIsReady] = useState(false)
    const onWebcamUserMedia = () => {
        navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
            setDevices(mediaDevices.filter(({ kind }: any) => kind === "videoinput"));
            setIsReady(true);
        });
    }


    return <>
        <div className={styles.overlay} style={{display: isTakePhotoModalVisible ? 'flex' : 'none'}}>
            <div className={styles.webcamWrapper}
                 style={{textAlign: 'center', display: isTakePhotoModalVisible ? 'block' : 'none'}}>
                {!isReady && <div className={styles.loader}><LoadingOutlined /></div>}
                {isTakePhotoModalVisible &&
                <Webcam
                    audio={false}
                    onUserMedia={onWebcamUserMedia}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{
                        ...videoConstraints,
                        facingMode,
                        width: 1280,
                        height: 720
                    }}
                />
                }
                <div className={styles.controls}>

                    <Button type={"primary"} onClick={() => {
                        setIsTakePhotoModalVisible(false)
                        setIsReady(false)
                    }}><CloseOutlined/></Button>

                    {devices.length > 1 &&
                    <Button type={"primary"} disabled={!isReady} onClick={() => {
                        handleSwitchCameraClick()
                        setIsReady(false)
                    }}><RetweetOutlined/></Button>
                    }

                    <Button type={"primary"} disabled={!isReady} onClick={(e) => {
                        e.preventDefault()
                        capture()
                        setIsTakePhotoModalVisible(false)
                        setIsReady(false)
                    }}><CameraOutlined/></Button>

                </div>
            </div>
        </div>
    </>
};