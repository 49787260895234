//AUTH TYPES
export const SET_AUTHED_USER_DATA = 'AUTH/SET_AUTHED_USER_DATA'

//ADMIN PANEL TYPES
export const SET_ORDERS_INFO = 'ADMIN_PANEL/SET_ORDERS_INFO'
export const UPDATE_ORDERS_INFO = 'ADMIN_PANEL/UPDATE_ORDERS_INFO'
export const DELETE_ORDER = 'ADMIN_PANEL/DELETE_ORDER'
export const SET_SETTINGS = 'ADMIN_PANEL/SET_SETTINGS'
export const SET_FILTERS = 'ADMIN_PANEL/SET_FILTERS'

//LOADER TYPES
export const HANDLE_LOADER = 'LOADER/HANDLE_LOADER'
export const HANDLE_ADMIN_TABLE_LOADER = 'LOADER/HANDLE_ADMIN_TABLE_LOADER'

//PACKAGE TYPES
export const SET_ORDER_INFO = 'PACKAGE/SET_ORDER_INFO'
export const DELETE_ATTACHMENT = 'PACKAGE/DELETE_ATTACHMENT'
export const DELETE_RICH_TEXT = 'PACKAGE/DELETE_RICH_TEXT'
export const SET_IS_VIDEO_UPLOADED = 'PACKAGE/SET_IS_VIDEO_UPLOADED'
export const SET_IS_UPLOADING_IN_PROGRESS = 'PACKAGE/SET_IS_UPLOADING_IN_PROGRESS'
export const RESET_IS_UPLOADING_IN_PROGRESS = 'PACKAGE/RESET_IS_UPLOADING_IN_PROGRESS'
export const ADD_UPLOAD_PROGRESS = 'PACKAGE/ADD_UPLOAD_PROGRESS'
export const SET_ORDER_MEDIA_ATTACHMENT = 'PACKAGE/SET_ORDER_MEDIA_ATTACHMENT'
export const SET_MEDIA_ATTACHMENT_PROCESSING_STATE_POLL = 'PACKAGE/SET_MEDIA_ATTACHMENT_PROCESSING_STATE_POLL'

//RIDDLE TYPES
export const SET_IS_RIDDLE_ADDED = 'RIDDLE/SET_IS_RIDDLE_ADDED'
export const SET_IS_RIDDLE_UPLOADING_IN_PROGRESS = 'RIDDLE/SET_IS_RIDDLE_ADDING_IN_PROGRESS'
export const SET_PREDEFINED_RIDDLE = 'RIDDLE/SET_PREDEFINED_RIDDLE'

//FAQ TYPES
export const SET_FAQ_INFO = 'RIDDLE/SET_FAQ_INFO'
