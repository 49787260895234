import {HANDLE_ADMIN_TABLE_LOADER, HANDLE_LOADER} from '../types';
import {InferActionsType} from "../store";

const initialState = {
    isLoading: false,
    isAdminTableLoading: false
};

export const loaderReducer = (state = initialState, action: ActionsTypes): InitialStateType => {
    switch (action.type) {
        case HANDLE_LOADER:
            return {
                ...state,
                isLoading: action.payload.isLoading
            };
        case HANDLE_ADMIN_TABLE_LOADER:
            return {
                ...state,
                isAdminTableLoading: action.payload.isAdminTableLoading
            };
        default:
            return state;
    }
};

export const loaderActions = {
    handleLoader: (isLoading: boolean) => ({
        type: HANDLE_LOADER,
        payload: {isLoading}
    } as const),
    handleAdminTableLoader: (isAdminTableLoading: boolean) => ({
        type: HANDLE_ADMIN_TABLE_LOADER,
        payload: {isAdminTableLoading}
    } as const)
}

type ActionsTypes = InferActionsType<typeof loaderActions>
export type InitialStateType = typeof initialState