import { useMemo } from "react"
// @ts-ignore
import { AzureMP } from 'react-azure-mp'

type VideoPlayerProps = {
    url: string
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ url }) => {
    return useMemo(() => (<AzureMP
        skin="amp-flush"
        src={[{ src: url, type: "application/vnd.ms-sstr+xml" }]}
    />), [url]);
}