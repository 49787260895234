import React from 'react';
import styles from './Footer.module.scss'
import {Link} from "react-router-dom"
import cn from "classnames";
import useWindowDimensions from "../../../helpers/getWindowDimensions";

export const Footer = () => {
    const {width, height} = useWindowDimensions()

    return (
        <div className={styles.footerContent}>
            <div className={cn(styles.footerInfo, styles.footerCol)}>
                <p>Nevíš si s něčím rady? Nedaří se ti vzkaz vytvořit? Zavolej nám na 608 963 772 a my ti hned pomůžeme. Mimo pracovní dny náš telefon ale rád odpočívá. O víkendech nebo ve svátek nám proto napiš radši e-mail na <a href="mailto:info@gifit.cz">info@gifit.cz</a>. Odpovědi se dočkáš rychlostí blesku, slibujeme.</p>
            </div>
        </div>
    );
};