import axiosInstance from "./axiosInstance";


export const adminService = {
    getOrdersInfo(pageSize: number, pageNumber: number) {
        return axiosInstance.post(`api/orders/filter`, {
            "paginationDto": {
                "take": pageSize,
                "skip": (pageNumber - 1) * pageSize
            }
        }).then(res => res.data);
    },

    filterOrders(statuses: string[], dateFrom: string, dateTo: string, orderNumber: string, pageSize: number, pageNumber: number, sortBy: string, sortOrder: string) {

        const filterConfig = {
            "filter": {

                "sortBy": `${sortBy}`,
                "sortOrder": `${sortOrder}`
            },
            "paginationDto": {
                "take": pageSize > 0 ? pageSize : 10,
                "skip": (pageNumber - 1) * pageSize
            }
        } as any

        if (statuses.length > 0) {
            const statusesFilter = statuses.map((status: string, index: number) => {
                return {
                    [`Status~${index + 1}`]: {
                        value: `${status}`
                    }
                }
            })

            filterConfig.filter = {
                ...filterConfig.filter,
                "equalityFilters": {}
            }

            for (let i = 0; i < statusesFilter.length; i++) {
                const key = Object.entries(statusesFilter[i]).map(item => (item[0])) as unknown as string
                const value = Object.entries(statusesFilter[i]).map(item => (item[1])) as unknown as string
                filterConfig.filter.equalityFilters[key] = value[0]
            }
        }

        if (dateFrom && dateTo) {
            filterConfig.filter = {
                ...filterConfig.filter,
                "rangeFilters": {
                    "startedDate": {
                        "from": `${dateFrom}`,
                        "to": `${dateTo}`
                    },
                }
            }
        }

        if (orderNumber) {
            filterConfig.filter = {
                ...filterConfig.filter,
                "containsFilters": {
                    "Number": {
                        "value": `${+orderNumber}`
                    }
                }
            }
        }

        return axiosInstance.post(`api/orders/filter`, {...filterConfig}).then(res => res.data);
    },

    sendOrder(orderId: string) {
        return axiosInstance.post(`/api/orders/${orderId}/send`).then(res => res.data);
    },

    unsendOrder(orderId: string) {
        return axiosInstance.post(`/api/orders/${orderId}/unsend`).then(res => res.data);
    },

    changeLink(orderId: string, link: string) {
        return axiosInstance.post(`/api/orders/${orderId}/link/?value=${link}`).then(res => res.data);
    },

    updateOrderCode(orderId: string, code: number) {
        return axiosInstance.put(`/api/orders/code`, {id: orderId, code}).then(res => res.data);
    },

    changeOrderStatus(orderId: string, status: string) {
        return axiosInstance.put(`/api/orders/change-state`, {id: orderId, NewStatus: status}).then(res => res.data);
    },

    getCurrentSettings() {
        return axiosInstance.get(`/api/settings`).then(res => res.data);
    },

    printOrderQR(orderId: string) {
        return axiosInstance.post(`/api/orders/${orderId}/print-qr`, {}, {responseType: 'blob'});
    },

    orderDelete(orderId: string) {
        return axiosInstance.delete(`/api/orders/${orderId}/soft`).then(res => res.data);
    },

    updateOrdersDefaultCode(newCode: string) {
        return axiosInstance.put(`/api/settings`, {name: 'DefaultCode', value: newCode}).then(res => res.data);
    }
}