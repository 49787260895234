import React, {useEffect} from 'react';
import "./YoutubeEmbed.scss"
import {useDispatch} from "react-redux";

type YoutubeEmbedProps = {
    url: string
}

export const checkYoutubeVideoURL = (url: string) => {
    const regExp = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
    return url.match(regExp)
}

export const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({url}) => {

    function youtube_parser(url: string){
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);
        return (match&&match[7].length == 11) ? match[7] : false;
    }


    return <div className="video-responsive">
        <iframe
            src={`https://www.youtube.com/embed/${youtube_parser(url)}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="Embedded youtube"
            allowFullScreen
        />
    </div>
};