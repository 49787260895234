import React, {useEffect, useState} from 'react';
import {Button, Modal} from "antd";
import {RightOutlined} from "@ant-design/icons";
import {MediaInputs} from "../../MediaInputs/MediaInputs";
import "./FirstStep.scss"
import {StepProps} from "../../../../pages/user/CreatePackage/CreatePackage";
import {useDispatch, useSelector} from "react-redux";
import {getOrderInfo, MediaUploadState, uploadAttachment} from "../../../../store/reducers/user/packageReducer";
import {NotificationError} from "../../../../utils/notifications";
import {ContentState, EditorState, convertToRaw} from "draft-js";
import{ stateToHTML} from "draft-js-export-html"
import draftToHtml from 'draftjs-to-html';
import {getIsUploadingInProgress, getIsVideoUploaded, getOrderRichText, getOrderStatus} from "../../../../store/selectors/packageSelectors";
import {checkYoutubeVideoURL} from "../../../../helpers/YoutubeEmbed/YoutubeEmbed";
import {v1 as uuidv1} from "uuid";
import {GenerateQR} from "../../GenerateQR/GenerateQR";
import {MediaTypes} from "../../../../helpers/MediaInfo";
import {History} from "history";
import {useHistory} from "react-router";
import {SavedMediaAttachmentType} from "../../../../models/Package";
import {OrderStatuses} from "../../../../utils/adminTable";
import htmlToDraft from 'html-to-draftjs';

export enum AttachmentTypes {
    RICH_TEXT = 'rich_text',
    MEDIA = 'media',
    YOUTUBE_LINK = 'youtube-link',
}

type FirstStepProps = {
    isVideoUploadTriggered: boolean,
    setIsGoToRiddleTriggered: (isTriggered: boolean) => void;
    setIsVideoUploadTriggered: (isTriggered: boolean) => void;
}


export const FirstStep: React.FC<StepProps & FirstStepProps> = ({
                                                                    setCurrent,
                                                                    isVideoUploadTriggered,
                                                                    setIsGoToRiddleTriggered,
                                                                    setIsVideoUploadTriggered
                                                                }) => {

    const dispatch = useDispatch()

    const history: History = useHistory()
    const orderId = history.location.pathname.split('/')[2]
    useEffect(() => {
        if (orderId) {
            sessionStorage.setItem('orderId', orderId)
        }
    }, [])

    const orderRichTextObj = useSelector(getOrderRichText)
    const isUploadingInProgress = useSelector(getIsUploadingInProgress)
    const orderStatus = useSelector(getOrderStatus)
    const isVideoUploaded = useSelector(getIsVideoUploaded)

    const [orderUploadedRichText, setOrderUploadedRichText] = useState('')
    useEffect(() => {
        if (orderRichTextObj && orderRichTextObj.length > 0) {
            setOrderUploadedRichText(orderRichTextObj[0].value)
        } else {
            setOrderUploadedRichText('')
        }
    }, [orderRichTextObj]);

    const [isModalVisible, setIsModalVisible] = useState(false);


    const [photos, setPhotos] = useState({
        uploadedFiles: [] as File[]
    });
    const [videos, setVideos] = useState({
        uploadedFiles: [] as File[]
    });
    const [gifs, setGifs] = useState({
        uploadedFiles: [] as File[]
    });
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
    const [youtubeLink, setYoutubeLink] = useState('')

    const [isTextActive, setIsTextActive] = useState(false)
    const [isPhotoActive, setIsPhotoActive] = useState(false)
    const [isVideoActive, setIsVideoActive] = useState(false)
    const [isGifActive, setIsGifActive] = useState(false)

    const [uploadedPhotos, setUploadedPhotos] = useState([] as SavedMediaAttachmentType[])
    const [uploadedVideo, setUploadedVideo] = useState({} as SavedMediaAttachmentType)
    const [uploadedGifs, setUploadedGifs] = useState([] as SavedMediaAttachmentType[])

    const [isQrGenerated, setIsQrGenerated] = useState(false)

    useEffect(() => {
        if (videos.uploadedFiles && videos.uploadedFiles.length > 0 && !videos.uploadedFiles[0].name) {
            //converting blob video from camera to File
            const myFile: File = new File([videos.uploadedFiles[0]], `video_${uuidv1()}.webm`, {
                type: 'video/webm',
            });

            setVideos({
                ...videos,
                uploadedFiles: [
                    myFile
                ]
            })
        }

    }, [videos.uploadedFiles]);


    useEffect(() => {
        if (orderStatus === OrderStatuses.SENT || orderStatus === OrderStatuses.DELETED || orderStatus === OrderStatuses.SAVED) {
            history.push(`/success`)
        }
    }, [])

    useEffect(() => {
        if (orderUploadedRichText) {
            const contentBlock = htmlToDraft(orderUploadedRichText);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState))
        }
    }, [orderUploadedRichText]);

    window.onbeforeunload = function () {
        if ((stateToHTML(editorState.getCurrentContent()).length !== orderUploadedRichText.length && editorState.getCurrentContent().getPlainText('\u0001').length > 0)
            || videos.uploadedFiles.length > 0 || photos.uploadedFiles.length > 0 || gifs.uploadedFiles.length > 0 || youtubeLink.length > 0
            || isUploadingInProgress.some((state:MediaUploadState) => state) || (isVideoUploadTriggered && !isVideoUploaded)) {
            return "Data will be lost if you reload the page, are you sure?";
        }

        if (isUploadingInProgress.some((isUploading) => isUploading)) {
            return "Data will be lost if you reload the page, are you sure?";
        }
    };

    const [isSendFilesTriggered, setIsSendFilesTriggered] = useState(false)

    const sendFiles = (triggeredFrom: 'QR' | 'Button' = 'Button') => {
        if (orderId) {
            setIsSendFilesTriggered(true)

            if (photos.uploadedFiles.length > 0) {
                photos.uploadedFiles.forEach((file: File) => {
                    dispatch(uploadAttachment(MediaTypes.PHOTO, orderId, file, '', AttachmentTypes.MEDIA, triggeredFrom))
                })
            }

            if (videos.uploadedFiles.length > 0) {
                videos.uploadedFiles.forEach((file: File) => {
                    setIsVideoUploadTriggered(true)
                    dispatch(uploadAttachment(MediaTypes.VIDEO, orderId, file, '', AttachmentTypes.MEDIA, triggeredFrom))
                })
            } else if (checkYoutubeVideoURL(youtubeLink)) {
                dispatch(uploadAttachment(MediaTypes.VIDEO, orderId, youtubeLink, '', AttachmentTypes.YOUTUBE_LINK, triggeredFrom))
            } else if (youtubeLink && !checkYoutubeVideoURL(youtubeLink)) {
                NotificationError('Chyba v nahrávání videa', 'Prosím vložte platný odkaz na youtube video')
            } else if (!isUploadingInProgress[2]) {
                setIsVideoUploadTriggered(false)
            }

            if (gifs.uploadedFiles.length > 0) {
                gifs.uploadedFiles.forEach((file: File) => {
                    dispatch(uploadAttachment(MediaTypes.GIF, orderId, file, '', AttachmentTypes.MEDIA, triggeredFrom))
                })
            }

            const editorStateContent = editorState.getCurrentContent().getPlainText('\u0001')
            const rawContentState = convertToRaw(editorState.getCurrentContent())

            const editorStateHTML = draftToHtml(rawContentState)
            if (isTextActive && editorStateContent.length > 0 && editorStateHTML !== orderUploadedRichText) {
                dispatch(uploadAttachment(MediaTypes.TEXT, orderId, null, editorStateHTML, AttachmentTypes.RICH_TEXT, triggeredFrom))
            }

            if (!isUploadingInProgress.some((state) => state)) {
                setPhotos({
                    uploadedFiles: []
                })
                setVideos({
                    uploadedFiles: []
                })
                setGifs({
                    uploadedFiles: []
                })
            }

        } else {
            NotificationError('Chyba', "Nebylo nalezeno číslo objednávky")
        }
    }

    const getIsButtonDisabled = () => {
        return (photos.uploadedFiles.length === 0 && videos.uploadedFiles.length === 0 && gifs.uploadedFiles.length === 0)
            && (editorState.getCurrentContent().getPlainText('\u0001').length === 0 || !isTextActive)
            && !youtubeLink
            && !uploadedVideo && uploadedGifs.length === 0 && uploadedPhotos.length === 0
            && !isUploadingInProgress.some(x => !!x);
    }

    return (
        <div>
            {/* <Modal visible={isModalVisible}
                   onCancel={() => setIsModalVisible(false)}
                   footer={[
                       <div className={"modalFooter"}>
                           <Button className={"cancel"} type={"default"} onClick={() => {
                               !isQrGenerated && sendFiles()
                               setCurrent(2)
                           }}>Ne, to je vše</Button>
                           <Button className={"ok"} type={"primary"} onClick={() => {
                               sendFiles()
                               setCurrent(1)
                               setIsGoToRiddleTriggered(true)
                           }}>Ano, chci hádanku! <RightOutlined/></Button>
                       </div>
                   ]}
            >
                <h1>Chcete k dárku přidat hádanku?</h1>
                <p className="modalDescription">Příjemce musí vyřešit hádanku a teprve poté obdrží kód k otevření
                    krabičky.</p>
            </Modal> */}
            <div className={"createPackageWrapper"}>
                <div>
                    <div className={"mainText"}>
                        <h1>Vykouzli obdarovanému úsměv na rtech!</h1>
                        <p>
                        Je čas rozhodnut se, jak bude tvoje přání vypadat. Můžeš použít jakoukoliv <span>fotku</span>, vlastnoručně nahrané <span>video</span>, všeříkající <span>gif</span> nebo prostě jen <span>text</span>. A nebo klidně všechno najednou.
                        </p>
                    </div>
                    <MediaInputs
                        isPhotoActive={isPhotoActive} setIsPhotoActive={setIsPhotoActive}
                        isVideoActive={isVideoActive} setIsVideoActive={setIsVideoActive}
                        isGifActive={isGifActive} setIsGifActive={setIsGifActive}
                        uploadedPhotos={uploadedPhotos} setUploadedPhotos={setUploadedPhotos}
                        uploadedVideo={uploadedVideo} setUploadedVideo={setUploadedVideo}
                        uploadedGifs={uploadedGifs} setUploadedGifs={setUploadedGifs}
                        isSendFilesTriggered={isSendFilesTriggered}
                        isTextActive={isTextActive} setIsTextActive={setIsTextActive}
                        orderRichText={orderUploadedRichText}
                        photos={photos} setPhotos={setPhotos}
                        videos={videos} setVideos={setVideos}
                        gifs={gifs} setGifs={setGifs}
                        editorState={editorState} setEditorState={setEditorState}
                        youtubeLink={youtubeLink} setYoutubeLink={setYoutubeLink}
                    />

                   <div className={"proceed"}>
                        <Button disabled={getIsButtonDisabled()}
                                type="primary" onClick={() => {
                               !isQrGenerated && sendFiles()
                               setCurrent(2)
                           }}>ULOŽIT A ZOBRAZIT NÁHLED<RightOutlined/></Button>
                          {/* <Button className={"cancel"} type={"default"} onClick={() => {
                               !isQrGenerated && sendFiles()
                               setCurrent(2)
                           }}>Uložit a
                            pokračovat</Button> */}
                    </div>

                    <GenerateQR isGenerated={isQrGenerated} setIsGenerated={setIsQrGenerated} sendFiles={sendFiles} getIsButtonDisabled={getIsButtonDisabled}/>
                </div>
            </div>
        </div>
    );
};
