import { CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import React from "react";
import "./FileUploadProgress.scss";
import { cancelUpload, MediaUploadState } from '../../../../store/reducers/user/packageReducer';
import { useDispatch, useSelector } from "react-redux";
import { MediaTypes } from "../../../../helpers/MediaInfo";
import { getOrderMedia } from "../../../../store/selectors/packageSelectors";
import { OrderMediaStatuses } from "../../../../models/Package";

type FileUploadProgressProps = {
    uploadState: MediaUploadState,
    mediaType: MediaTypes,
    noCancel?: boolean
}

export const FileUploadProgress: React.FC<FileUploadProgressProps> = ({ uploadState, mediaType, noCancel = false }) => {

    const dispatch = useDispatch()
    const orderMediaAttachments = useSelector(getOrderMedia)

    const handleCloseClick = () => {
        if (uploadState && uploadState.cancelToken) {
            const attachment = orderMediaAttachments.find(x => x.type === mediaType && x.status === OrderMediaStatuses.IN_PROGRESS);
            dispatch(cancelUpload(mediaType, uploadState.cancelToken, attachment));
        }
    };

    return (
        <div className={"loadingOverlay fileUploadProgress"}>
            <div className={"loadingWrapper"}>
                <LoadingOutlined />
                <div className={"progressPercent"}>
                    {uploadState !== false && Math.floor(uploadState?.progress || 0) + '%'}
                </div>
            </div>
            {!noCancel &&
                <span className={"closeIcon"}
                    onClick={handleCloseClick}
                >
                    <CloseCircleOutlined />

                </span>
            }
        </div>
    );
};
