import React, {useEffect} from 'react';
import styles from "./Header.module.scss";
import logo from "../../../assets/images/logo.png";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import {History} from "history";
import {useHistory} from "react-router";

export const Header = () => {

    const history: History = useHistory()
    const orderNumber = history.location.search.split('=')[1]

    useEffect(() => {
        if (orderNumber) {
            sessionStorage.setItem('orderNumber', orderNumber);
        }
    }, [orderNumber])

    return (
        <div>
            <div className={styles.headerContent}>
                <div className={styles.logoWrapper}>
                    <Link to={orderNumber ? `/?orderNumber=${orderNumber}` : (sessionStorage.getItem('orderNumber')
                        ?`/?orderNumber=${sessionStorage.getItem('orderNumber')}` : '/')}>
                        <img src={logo} alt="logo"/>
                    </Link>

                </div>
                <div className={styles.links}>
                    {/*<p><Link target={"_blank"} to={'/'}>Koupit <FontAwesomeIcon icon={faExternalLinkAlt}/></Link></p>
                    <p><Link target={"_blank"} to={'/faq'}>FAQ</Link></p>*/}
                </div>
            </div>
        </div>
    );
};