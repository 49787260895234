import {NotificationError} from "../../../utils/notifications";
import {ResponseStatuses} from "../../../api/axiosInstance";
import {BaseThunkType, InferActionsType} from "../../store";
import {SET_FAQ_INFO} from "../../types";
import {loaderActions} from "../loaderReducer";
import {FAQService} from "../../../api/FAQService";
import {FAQModel} from "../../../models/FAQ";


let initialState = {
    FAQInfo: [] as FAQModel[]
};


export const FAQReducer = (state = initialState, action: ActionsTypes): InitialStateType => {
    switch (action.type) {
        case SET_FAQ_INFO:
            return {
                ...state,
                FAQInfo: action.payload.data
            }
        default:
            return state;
    }
}


export const FAQActions = {
    setFAQInfo: (data: any) => ({
        type: SET_FAQ_INFO,
        payload: {data}
    } as const),
}

export const getFAQInfo = (): ThunkType =>
    async (dispatch) => {

        try {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(true))

            const data = await FAQService.getInfo();

            if (data.status === ResponseStatuses.SUCCESS) {
                dispatch(FAQActions.setFAQInfo(data.data));
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(false))
        }
    }

type ActionsTypes = InferActionsType<typeof FAQActions>
export type InitialStateType = typeof initialState
export type ThunkType = BaseThunkType<ActionsTypes>