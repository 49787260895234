import React, {useEffect} from 'react';
import styles from './Settings.module.scss'
import {Form, Formik, Field as FormikField} from "formik";
import {CheckOutlined} from "@ant-design/icons";
import * as Yup from "yup";
import {Input} from "antd";
import {getSettings, updateDefaultCode} from "../../../store/reducers/admin/adminPanelReducer";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentSettingsArray} from "../../../store/selectors/adminPanelSelectors";

export const Settings = () => {

    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
        code: Yup
            .string()
            .length(4)
    });

    const settings = useSelector(getCurrentSettingsArray)
    const defaultCode = settings.filter((item: { name: string, value: string }) => {
        return item.name === 'DefaultCode'
    })

    const initialValues = {
        code: defaultCode[0] ? defaultCode[0].value : ''
    }

    useEffect(() => {
        dispatch(getSettings())
    }, [])


    return <div className={styles.wrapper}>
        <h1>Nastavení</h1>
        {/* <div className={styles.formWrapper}>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    dispatch(updateDefaultCode(values.code, setSubmitting, resetForm))
                }}
            >
                {({errors, touched}) => (
                    <Form>
                        <label htmlFor="code">Defaultni hodnota kodu</label>
                        <div className={styles.wrapper}>
                            <FormikField
                                name="code"
                                render={({field}: any) =>
                                    <Input
                                        //value={defaultCode[0] && defaultCode[0].value}
                                        autoComplete="off"
                                        style={errors.code && touched.code && {border: '1px solid red'}}
                                        {...field}
                                        type="text"
                                        placeholder="0 0 0 0"/>
                                }
                            />
                            <button type="submit" className={styles.adminTableBtn}><CheckOutlined /></button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div> */}
    </div>
};