import React from 'react';
import styles from './SigninForm.module.scss'
import {Formik, Form, Field as FormikField} from "formik";
import * as Yup from "yup";
import logoText from '../../../assets/images/logo.png'
import {Button, Checkbox, Input} from "antd";
import {UserOutlined, KeyOutlined} from '@ant-design/icons';
import {useDispatch} from "react-redux";
import {loginUser} from "../../../store/reducers/admin/authReducer";
import {useHistory} from "react-router";
import { History } from 'history';

type SignInFormValues = {
    login: string;
    password: string;
    rememberMe: boolean;
}

export const SigninForm = () => {

    const ValidationSchema = Yup.object().shape({
        login: Yup.string().required("Username is required"),
        password: Yup.string().required("Password is required")
    });

    const dispatch = useDispatch()
    const history: History = useHistory()

    const onSubmit = (values: SignInFormValues, {setSubmitting, resetForm}: any) => {
        dispatch(loginUser(values.login, values.password, values.rememberMe, resetForm, setSubmitting, history))
    }

    return <>
        <div className={styles.overlay}>
            <div className={styles.contentWrapper}>

                <div className={styles.formWrapper}>
                    <img className={styles.logo} src={logoText} alt="logo"/>
                    <p className={styles.description}>Přihlášení do panelu objednávek</p>

                    <Formik
                        validationSchema={ValidationSchema}
                        initialValues={{login: "", password: "", rememberMe: false}}
                        onSubmit={onSubmit}
                    >
                        {({errors, touched, isSubmitting}) => (
                            <Form>
                                <div className={styles.formItem}>
                                    <label htmlFor="login">Username</label>
                                    <FormikField
                                        name="login"
                                        render={({field}: any) =>
                                            <Input
                                                style={errors.login && touched.login && {border: '1px solid red'}}
                                                prefix={<UserOutlined/>}
                                                {...field}
                                                placeholder="Username"/>
                                        }
                                    />
                                    <div className={styles.errorMessage}>{(errors.login && touched.login) && errors.login}</div>
                                </div>


                                <div className={styles.formItem}>
                                    <label htmlFor="password">Password</label>
                                    <FormikField
                                        name="password"
                                        render={({field}: any) =>
                                            <Input
                                                style={errors.password && touched.password && {border: '1px solid red'}}
                                                prefix={<KeyOutlined/>}
                                                {...field} type={'password'}
                                                placeholder="Password"/>
                                        }
                                    />
                                    <div className={styles.errorMessage}>{(errors.password && touched.password) && errors.password}</div>
                                </div>

                                <div className={styles.controls}>
                                    <div className={styles.rememberMe}>
                                        <label htmlFor="rememberMe">Zůstat v systému</label>
                                        <FormikField
                                            name="rememberMe"
                                            render={({field}: any) =>
                                                <Checkbox {...field}/>}
                                        />
                                    </div>
                                    <Button className={styles.submitBtn} disabled={isSubmitting} htmlType="submit"
                                            type="primary">Přihlásit
                                        se</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>

    </>
};