import React from 'react';
import './Loader.scss';

export const Loader = () => {
    return (
        <div className="loader-block">
            <div className="loader" id="loader-4">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
}