import React, {useEffect, useState} from 'react';
import styles from './Orders.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {getOrdersInfo, getOrdersTotalCount} from "../../../store/selectors/adminPanelSelectors";
import {Space, Table, Tag} from 'antd';
import dayjs from 'dayjs'
import {OrderType} from "../../../models/Orders";
import cn from "classnames";
import {getIsAdminTableLoading} from "../../../store/selectors/loaderSelectors";
import {getOrders, printQR, sendOrder, changeLink} from "../../../store/reducers/admin/adminPanelReducer";
import {MoreActions} from "../../../components/admin/TableColumns/MoreActions";
import {CodeUpdate} from "../../../components/admin/TableColumns/CodeUpdate";
import {
    getTranslatedStatuses,
    IsModalVisibleItem,
    OrderStatuses,
    TranslatedOrderStatuses
} from "../../../utils/adminTable";
import {Filters} from "../../../components/admin/Filters/Filters";
import {ordersFilter} from "../../../store/actions/Filters";
import {CaretUpOutlined, CaretDownOutlined} from "@ant-design/icons";
import {NotificationError} from "../../../utils/notifications";

export const getProperTag = (status: string) => {
    if (status === OrderStatuses.SAVED || status === TranslatedOrderStatuses.SAVED) {
        return <Tag className={styles.statusTag} color={'cyan'}>Uložená</Tag>
    } else if (status === OrderStatuses.DELETED || status === TranslatedOrderStatuses.DELETED) {
        return <Tag className={styles.statusTag} color={'red'}>Smazaná</Tag>
    } else if (status === OrderStatuses.SENT || status === TranslatedOrderStatuses.SENT) {
        return <Tag className={styles.statusTag} color={'green'}>Odeslaná</Tag>
    } else if (status === OrderStatuses.IN_PROGRESS || status === TranslatedOrderStatuses.IN_PROGRESS) {
        return <Tag className={styles.statusTag} color={'purple'}>Rozpracovaná</Tag>
    } else if (status === OrderStatuses.LAUNCHED || status === TranslatedOrderStatuses.LAUNCHED) {
        return <Tag className={styles.statusTag} color={'blue'}>Zahájená</Tag>
    }
}

export const Orders = () => {

        const orders = useSelector(getOrdersInfo)
        const ordersTotalCount = useSelector(getOrdersTotalCount)
        const isAdminTableLoading = useSelector(getIsAdminTableLoading)

        const dispatch = useDispatch()

        const [selectedPageNumber, setSelectedPageNumber] = useState(1);
        const [selectedPageSize, setSelectedPageSize] = useState<number | undefined>(10);
        const [dateFrom, setDateFrom] = useState('');
        const [dateTo, setDateTo] = useState('');
        const [selectedStatuses, setSelectedStatuses] = useState([] as string[]);
        const [translatedStatuses, setTranslatedStatuses] = useState([] as string[]);
        const [orderNumber, setOrderNumber] = useState('');

        useEffect(() => {
            dispatch(ordersFilter(getTranslatedStatuses(selectedStatuses) as any, dateFrom, dateTo, orderNumber, selectedPageSize, selectedPageNumber,
            colSortBy, colSortType))
        }, []);

        const send = (orderId: string, send: boolean) => {
            dispatch(sendOrder(orderId, send))
        }

        const validateUrl = (value: string) => {
            const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
            return regex.test(value);
          }

        const linkChanged = (orderId: string, value: string, oldValue: string) : boolean => {
            const check = validateUrl(value)

            if((!oldValue && !value) || oldValue === value){
                return true;
            }
            else if((oldValue !== value && check) || (oldValue && value === '')) {
                dispatch(changeLink(orderId, value));
                return true;
            }
            else{
                NotificationError('Chyba', 'Nejde uložit neplatnou URL adresu.');
                return false;
            }
        }

        const printQRCode = (orderId: string, orderNumber: number) => {
            dispatch(printQR(orderId, orderNumber))
        }

        const [colSortBy, setColSortBy] = useState('StartedDate')
        const [colSortType, setColSortType] = useState('Descending')

        const handleHeaderClick = (row: any) => {
            const originSortBy = colSortBy;
            const originSortType = colSortType;
            return {
                onClick: () => {
                    let sortBy;
                    let sortType = 'Ascending';
                    const dataIndex = row.dataIndex.charAt(0).toUpperCase() + row.dataIndex.slice(1);
                    if (!originSortBy) {
                        // If not sorted, the positive order
                        sortBy = dataIndex;
                    } else if (originSortBy === dataIndex) {
                        // If they are equal, then transform
                        if (originSortType === 'Ascending') {
                            sortBy = originSortBy;
                            sortType = 'Descending';
                        } else if (originSortType === 'Descending') {
                            sortType = 'Ascending';
                        }
                    } else {
                        // If not equal
                        sortBy = dataIndex;
                    }

                    setColSortBy(sortBy)
                    setColSortType(sortType)

                    dispatch(ordersFilter(getTranslatedStatuses(selectedStatuses) as any, dateFrom, dateTo, orderNumber, selectedPageSize, selectedPageNumber,
                        sortBy, sortType))
                },
            }
        }

        const [isCodeModalVisible, setIsCodeModalVisible] = useState<IsModalVisibleItem>({});

        const [isMoreActionsModalVisible, setIsMoreActionsModalVisible] = useState<IsModalVisibleItem>({});
        const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<IsModalVisibleItem>({});

        const columns = [
            {
                title:  <div className={styles.headerItem}>Zakázka č. {colSortBy === 'Number' ?
                    (colSortType === 'Ascending' ? <CaretUpOutlined/> : <CaretDownOutlined/>)
                    : <div className={styles.defaultSorter}><CaretUpOutlined/> <CaretDownOutlined/> </div>}</div>,
                dataIndex: 'number',
                key: 'number',
                onHeaderCell: (row: any) => handleHeaderClick(row)
            },
            {
                title: <div className={styles.headerItem}>Zahájení {colSortBy === 'StartedDate' ?
                    (colSortType === 'Ascending' ? <CaretUpOutlined/>
                        :  <CaretDownOutlined/>)
                    : <div className={styles.defaultSorter}><CaretUpOutlined/> <CaretDownOutlined/> </div>}</div>,
                key: 'startedDate',
                dataIndex: 'startedDate',
                onHeaderCell: (row: any) => handleHeaderClick(row),
                render: (startedDate: string) => {
                    return <Space size="middle">
                        {startedDate ? dayjs(startedDate).format('DD-MM-YYYY HH:mm') : ''}
                    </Space>
                }
            },
            {
                title: <div className={styles.headerItem}>Uložení {colSortBy === 'SavedDate' ?
                (colSortType === 'Ascending' ? <CaretUpOutlined/>
                    :  <CaretDownOutlined/>)
                : <div className={styles.defaultSorter}><CaretUpOutlined/> <CaretDownOutlined/> </div>}</div>
                ,
                key: 'savedDate',
                dataIndex: 'savedDate',
                onHeaderCell: (row: any) => handleHeaderClick(row),
                render: (savedDate: string) => (
                    <Space size="middle">
                        {savedDate ? dayjs(savedDate).format('DD-MM-YYYY HH:mm') : ''}
                    </Space>
                )
            },
            {
                title: <div className={styles.headerItem}>Odeslání {colSortBy === 'SentDate' ?
                (colSortType === 'Ascending' ? <CaretUpOutlined/>
                    :  <CaretDownOutlined/>)
                : <div className={styles.defaultSorter}><CaretUpOutlined/> <CaretDownOutlined/> </div>}</div>
                ,
                key: 'sentDate',
                dataIndex: 'sentDate',
                onHeaderCell: (row: any) => handleHeaderClick(row),
                render: (sentDate: string) => (
                    <Space size="middle">
                        {sentDate ? dayjs(sentDate).format('DD-MM-YYYY HH:mm') : ''}
                    </Space>
                )
            },
            // {
            //     title: 'Kod',
            //     key: 'code',
            //     dataIndex: 'code',
            // },
            {
                title: <div className={styles.headerItem}>Stav {colSortBy === 'Status' ?
                (colSortType === 'Ascending' ? <CaretUpOutlined/>
                    :  <CaretDownOutlined/>)
                : <div className={styles.defaultSorter}><CaretUpOutlined/> <CaretDownOutlined/> </div>}</div>
                ,
                key: 'status',
                dataIndex: 'status',
                onHeaderCell: (row: any) => handleHeaderClick(row),
                render: (status: string) => {
                    return <span>{getProperTag(status)}</span>
                }
            },
            {
                title: 'Operace',
                key: 'operation',
                render: (order: OrderType) => {
                    if(order && order.status === OrderStatuses.SAVED)
                    {
                        return <Space size="middle">
                                <button onClick={() => send(order.id, true)}
                                        className={cn(styles.sendBtn, styles.adminTableBtn)}>Odeslat</button>
                                </Space>
                    }
                    else if(order && order.status === OrderStatuses.SENT)
                    {
                        return <Space size="middle">
                                <button onClick={() => send(order.id, false)}
                                    className={cn(styles.sendBtnDisabled, styles.adminTableBtn)}>Odesláno</button>
                                </Space>
                    }
                }
            },
            {
                title: 'Odkaz',
                key: 'link',
                render: (order: OrderType) => (
                    <Space size="middle">
                        <input type='text' defaultValue={order.link} onBlur={(e) => {
                             let changeSuccess = linkChanged(order.id, e.target.value, order.link);
                             if(!changeSuccess) {
                                 e.target.value = order.link ?? '';
                             }
                        }} />
                    </Space>
                )
            },
            {
                title: '',
                key: 'print',
                render: (order: OrderType) => (
                    <Space size="middle">
                        <button onClick={() => printQRCode(order.id, order.number)}
                                className={cn(styles.printBtn, styles.adminTableBtn)}>Tisknout
                        </button>
                    </Space>
                ),
            },
            // {
            //     title: '',
            //     key: 'showCode',
            //     render: (order: OrderType) => (
            //         <Space size="middle">
            //             <CodeUpdate isCodeModalVisible={isCodeModalVisible} setIsCodeModalVisible={setIsCodeModalVisible}
            //                         order={order}/>
            //         </Space>
            //     ),
            // },
            {
                title: '',
                key: 'showMore',
                render: (order: OrderType) => (
                    <Space size="middle">
                        <MoreActions isMoreActionsModalVisible={isMoreActionsModalVisible}
                                     setIsMoreActionsModalVisible={setIsMoreActionsModalVisible}
                                     isConfirmModalVisible={isConfirmModalVisible}
                                     setIsConfirmModalVisible={setIsConfirmModalVisible}
                                     order={order}/>
                    </Space>
                ),
            },
        ];


        const [data, setData] = useState<OrderType[] | []>([])
        useEffect(() => {
            const newData = orders.length > 0 && orders.map((order: OrderType) => {
                return {
                    ...order,
                    key: order.number,
                }
            })
            setData(newData)
        }, [JSON.stringify(orders)]);

        const handlePaginatorChange = (pageNumber: number, pageSize: number | undefined) => {
            window.scroll(0, 0)

            setSelectedPageNumber(pageNumber)
            setSelectedPageSize(pageSize)
            dispatch(ordersFilter(getTranslatedStatuses(selectedStatuses) as any, dateFrom, dateTo, orderNumber, pageSize, pageNumber,
            colSortBy, colSortType))
        }

        return (
            <div className={styles.wrapper}>
                <h1>Seznam vzkazů</h1>
                <div className={styles.tableContent}>
                    <Filters
                        dateFrom={dateFrom} setDateFrom={setDateFrom}
                        dateTo={dateTo} setDateTo={setDateTo}
                        selectedStatuses={selectedStatuses} setSelectedStatuses={setSelectedStatuses}
                        translatedStatuses={translatedStatuses} setTranslatedStatuses={setTranslatedStatuses}
                        orderNumber={orderNumber} setOrderNumber={setOrderNumber}
                        selectedPageNumber={selectedPageNumber} selectedPageSize={selectedPageSize} 
                        sortBy={colSortBy} sortType={colSortType}/>
                    {orders.length > 0 &&
                    <>
                        <Table
                            loading={isAdminTableLoading}
                            pagination={{
                                pageSizeOptions: ['10', '20', '30', '40'],
                                showSizeChanger: true,
                                total: ordersTotalCount,
                                onChange: handlePaginatorChange,
                                showQuickJumper: true,
                                defaultCurrent: 1
                            }}
                            columns={columns}
                            dataSource={data}
                        />
                    </>
                    }
                </div>
            </div>
        );
    }
;