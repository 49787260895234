import axios from 'axios';
import {useDispatch} from "react-redux";
import {logoutUser} from "../store/reducers/admin/authReducer";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    transformRequest: [
        function (data, headers = {}) {
            if (Boolean(localStorage.getItem('token')) && localStorage.getItem('token') !== 'undefined') {
                headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
            } else if (Boolean(sessionStorage.getItem('token')) && sessionStorage.getItem('token') !== 'undefined') {
                headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
            }

            if (headers['Content-Type'] !== 'multipart/form-data') {
                headers['Content-Type'] = 'application/json';
                if (typeof data !== 'string') {
                    data = JSON.stringify(data);
                }
            }
            if (!headers['token']) {
                delete headers['token'];
            }

            return data;
        },
    ],
});

axiosInstance.interceptors.response.use(
    async response => {
        return Promise.resolve(response);
    },
    async error => {
        if (error.response && error.response.status === 401) {
            /*localStorage.clear();
            sessionStorage.clear();*/
            const dispatch = useDispatch()
            dispatch(logoutUser())
        }

        return Promise.reject(error.response || error);
    },
);


export enum ResponseStatuses {
    SUCCESS = 'Success',
    ACCEPTED = 'Accepted'
}

export default axiosInstance;
