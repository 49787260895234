import {AppStateType} from "../store";

export const getOrderIdSelector = (state: AppStateType) => {
    return state.packageInfo.orderInfo.id
}

export const getOrderMedia = (state: AppStateType) => {
    return state.packageInfo.orderInfo.mediaAttachments
}

export const getOrderRichText = (state: AppStateType) => {
    return state.packageInfo.orderInfo.richTextAttachments
}

export const getIsVideoUploaded = (state: AppStateType) => {
    return state.packageInfo.isVideoUploaded
}

export const getOrderRiddle = (state: AppStateType) => {
    return state.packageInfo.orderInfo.riddle
}

export const getOrderCode = (state: AppStateType) => {
    return state.packageInfo.orderInfo.code
}

export const getOrderStatus = (state: AppStateType) => {
    return state.packageInfo.orderInfo.status
}

export const getOrderLink = (state: AppStateType) => {
    return state.packageInfo.orderInfo.link
}

export const getOrderId = (state: AppStateType) => {
    return state.packageInfo.orderInfo.id
}

export const getIsUploadingInProgress = (state: AppStateType) => {
    return state.packageInfo.isMediaUploadingInProgress
}


export const getIsRiddleUploadingInProgress = (state: AppStateType) => {
    return state.riddleInfo.isRiddleUploadingInProgress
}

export const getPredefinedRiddles = (state: AppStateType) => {
    return state.riddleInfo.predefinedRiddles
}
