import axiosInstance from "./axiosInstance";
import fileDownload from "js-file-download";
import {MediaTypes} from "../helpers/MediaInfo";
import { CancelTokenSource } from "axios";

export const packageService = {
    getOrderInfoByNumber(orderNumber: string) {
        return axiosInstance.get(`api/orders?number=${orderNumber}`)
            .then(res => res.data);
    },

    getOrderInfoById(orderId: string) {
        return axiosInstance.get(`api/orders/${orderId}`)
            .then(res => res.data);
    },

    sendMediaAttachment(attachmentType: MediaTypes, orderId: string, file: File, cancelToken?: CancelTokenSource) {

        let formData = new FormData();
        formData.append('attachment', file);
        formData.append('attachmentType', attachmentType);

        let requestTimeout = 0
        if (process.env.REACT_APP_MEDIA_ATTACHEMNT_REQUEST_TIMEOUT) {
            requestTimeout = +process.env.REACT_APP_MEDIA_ATTACHEMNT_REQUEST_TIMEOUT
        }

        return axiosInstance.post(`api/orders/${orderId}/media-attachment`, formData, {
            headers: {
                'Content-Type': "multipart/form-data"
            },
            timeout: requestTimeout,
            cancelToken: cancelToken ? cancelToken.token : undefined
        }).then(res => res.data);
    },

    sendYoutubeLink(orderId: string, url: string) {
        return axiosInstance.post(`api/orders/${orderId}/media-attachment/url`,
            {
                attachmentType: "Video",
                url: `${url}`
            })
            .then(res => res.data);
    },

    deleteMediaAttachment(orderId: string, attachmentId: string) {
        return axiosInstance.delete(`/api/orders/${orderId}/media-attachment/${attachmentId}`)
            .then(res => res.data);
    },

    sendRichTextAttachment(orderId: string, text: string) {
        return axiosInstance.post(`api/orders/${orderId}/rich-text-attachment`, {value: text})
            .then(res => res.data);
    },

    deleteRichTextAttachment(orderId: string, attachmentId: string) {
        return axiosInstance.delete(`/api/orders/${orderId}/rich-text-attachment/${attachmentId}`)
            .then(res => res.data);
    },

    saveOrder(orderId: string) {
        return axiosInstance.post(`/api/orders/${orderId}/save`)
            .then(res => res.data);
    },

    getDownloadUrl(orderId: string, attachmentId: string) {
        return axiosInstance.get(`/api/orders/${orderId}/media-attachment/${attachmentId}/temporary-download-url`)
            .then(res => res.data);
    }
}
