import "./Page404.scss"
import {Button} from "antd";
import {RightOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom"
import {History} from "history";
import {useHistory} from "react-router";
import {useEffect} from "react";

export const PageNotFound = () => {


    useEffect(() => {
        sessionStorage.setItem('currentStep', '0')
    }, [])

    return <div className={"page404Wrapper"}>
        <div className={"content"}>
            <h1>404</h1>
            <p>Něco se pokazilo 🤔</p>

            <Link to={sessionStorage.getItem('orderNumber') ? `/?orderNumber=${sessionStorage.getItem('orderNumber')}` : '/'}>
                <Button type={"primary"} className={"goToHomepage"}>Úvodní stránka <RightOutlined/></Button>
            </Link>
        </div>
    </div>
};
