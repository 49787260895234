import React, {useEffect, useState} from 'react';
import "./MediaInputs.scss";
import "../CreatePackage/ThirdStep/ThirdStep.scss";
import cn from "classnames";
import {CloseCircleOutlined, CloseOutlined, LoadingOutlined, PaperClipOutlined} from "@ant-design/icons";
import {FileUpload, handleFileClose, handleSavedFileDelete} from "./FileUpload/FileUpload";
import {Input} from "antd";
import {Editor} from "react-draft-wysiwyg";
import {EditorState} from 'draft-js';

import photoIcon from "../../../assets/images/CreatePackagePage/photo-icon.svg"
import gifIcon from "../../../assets/images/CreatePackagePage/gif-icon.svg"
import textIcon from "../../../assets/images/CreatePackagePage/text-icon.svg"
import videoIcon from "../../../assets/images/CreatePackagePage/video-icon.svg"
import photoIconWhite from "../../../assets/images/CreatePackagePage/photo-icon-white.svg"
import gifIconWhite from "../../../assets/images/CreatePackagePage/gif-icon-white.svg"
import textIconWhite from "../../../assets/images/CreatePackagePage/text-icon-white.svg"
import videoIconWhite from "../../../assets/images/CreatePackagePage/video-icon-white.svg"
import {NotificationWarning} from "../../../utils/notifications";
import {CameraOpen, CameraOpenTypes} from "./CameraOpen/CameraOpen";
import {newRiddleToolbar, toolbar} from "../../../utils/textEditorConfig";
import {useDispatch, useSelector} from "react-redux";
import {getIsUploadingInProgress, getIsVideoUploaded, getOrderMedia, getOrderRichText} from "../../../store/selectors/packageSelectors";
import {OrderMediaStatuses, SavedMediaAttachmentType} from "../../../models/Package";
import {checkYoutubeVideoURL, YoutubeEmbed} from "../../../helpers/YoutubeEmbed/YoutubeEmbed";
import {AttachmentTypes} from "../CreatePackage/FirstStep/FirstStep";
import {deleteAttachment} from "../../../store/reducers/user/packageReducer";
import {Loader} from "../../common/Loader/Loader";
import {onEditorStateChange} from "../../../helpers/onEditorStateChange";
import {FilesLimitations, MediaTypes} from "../../../helpers/MediaInfo";
import {FileUploadProgress} from "./FileUploadProgress/FileUploadProgress";
import { VideoPlayer } from './VideoPlayer/VideoPlayer';

type MediaInputsProps = {
    isTextActive: boolean;
    setIsTextActive: (isActive: boolean) => void;
    orderRichText: string;
    photos: { uploadedFiles: File[] };
    setPhotos: any;
    videos: { uploadedFiles: File[] };
    setVideos: any;
    gifs: { uploadedFiles: File[] };
    setGifs: any;
    editorState: EditorState | undefined;
    setEditorState: (editorState: EditorState) => void;
    youtubeLink: string,
    setYoutubeLink: (link: string) => void;
    isSendFilesTriggered: boolean;
    uploadedPhotos: SavedMediaAttachmentType[];
    setUploadedPhotos: any;
    uploadedVideo: SavedMediaAttachmentType;
    setUploadedVideo: any;
    uploadedGifs: SavedMediaAttachmentType[];
    setUploadedGifs: any;
    isPhotoActive: any;
    setIsPhotoActive: any;
    isVideoActive: any;
    setIsVideoActive: any;
    isGifActive: any;
    setIsGifActive: any;
}

const getUploadedVideoTitle = (url: string) => {
    const title = url.split('.ism')[0].split('/')[4].replace('%', '')
    // @ts-ignore
    const maxVideoTitleLength = +process.env.REACT_APP_MAX_VIDEO_TITLE_LENGTH

    return title.length > maxVideoTitleLength ? (title.substring(0, maxVideoTitleLength) + '...') : title
}

export const bytesInMb = 1048576

export const MediaInputs: React.FC<MediaInputsProps> = ({
                                                            isPhotoActive, setIsPhotoActive,
                                                            isVideoActive, setIsVideoActive,
                                                            isGifActive, setIsGifActive,
                                                            uploadedPhotos,
                                                            uploadedVideo,
                                                            uploadedGifs,
                                                            setUploadedPhotos,
                                                            setUploadedVideo,
                                                            setUploadedGifs,
                                                            isSendFilesTriggered,
                                                            isTextActive,
                                                            setIsTextActive,
                                                            orderRichText,
                                                            photos,
                                                            setPhotos,
                                                            videos,
                                                            setVideos,
                                                            gifs,
                                                            setGifs,
                                                            editorState,
                                                            setEditorState,
                                                            youtubeLink,
                                                            setYoutubeLink
                                                        }) => {

    const dispatch = useDispatch()
    const isVideoUploaded = useSelector(getIsVideoUploaded)
    const isUploadingInProgress = useSelector(getIsUploadingInProgress)


    // @ts-ignore
    const maxVideoTitleLength = +process.env.REACT_APP_MAX_VIDEO_TITLE_LENGTH

    const orderMediaAttachments = useSelector(getOrderMedia)
    const mediaAttachments = useSelector(getOrderMedia)
    const orderRichTextObj = useSelector(getOrderRichText)

    useEffect(() => {
        if (orderRichText) {
            setIsTextActive(true)
        }

        if (uploadedVideo && uploadedVideo.url) {
            setIsVideoActive(true)
        }
    },[orderRichText, uploadedVideo]);

    useEffect(() => {
        if (orderMediaAttachments && orderMediaAttachments.length >= 0) {
            const photos = orderMediaAttachments.filter((item: any) => (item.type === MediaTypes.PHOTO));
            const video = orderMediaAttachments.filter((item: any) => (item.type === MediaTypes.VIDEO))[0];
            const gifs = orderMediaAttachments.filter((item: any) => (item.type === MediaTypes.GIF));

            setUploadedPhotos(photos)
            setUploadedVideo(video)
            setUploadedGifs(gifs)

            if(!updatedIsActive){        
                if (photos.length > 0) {
                    setIsPhotoActive(true)
                } 
        
                if (gifs.length > 0) {
                    setIsGifActive(true)
                }
                
                setUpdatedIsActive(true);
            }
        }
    }, [orderMediaAttachments]);


    const [videoDuration, setVideoDuration] = useState('')
    const [updatedIsActive, setUpdatedIsActive] = useState(false)

    let photoMaxSize: number
    let videoMaxSize: number
    let gifMaxSize: number


    if (process.env.REACT_APP_VIDEO_MAX_SIZE_MB) {
        videoMaxSize = +process.env.REACT_APP_VIDEO_MAX_SIZE_MB
    }
    if (process.env.REACT_APP_PHOTO_MAX_SIZE_MB) {
        photoMaxSize = +process.env.REACT_APP_PHOTO_MAX_SIZE_MB
    }
    if (process.env.REACT_APP_GIF_MAX_SIZE_MB) {
        gifMaxSize = +process.env.REACT_APP_GIF_MAX_SIZE_MB
    }

    function setVideoInfo() {
        if (videos.uploadedFiles[0].name) {
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.src = URL.createObjectURL(videos.uploadedFiles[0]);

            video.onloadedmetadata = function () {
                window.URL.revokeObjectURL(video.src);
                const durationSec = video.duration;

                const d = Number(durationSec);
                const h = Math.floor(d / 3600);
                const m = Math.floor(d % 3600 / 60);
                const s = Math.floor(d % 3600 % 60);

                if ((d && h && m && s) || (h === 0 || m === 0 || s === 0)) {
                    const hDisplay = h > 0 ? h < 10 ? `0${h}:` : `${h}` : '0:';
                    const mDisplay = m > 0 ? m < 10 ? `0${m}:` : `${m}` : '00:';
                    const sDisplay = s < 10 ? `0${s}` : s;

                    setVideoDuration(hDisplay + mDisplay + sDisplay)
                } else {
                    setVideoDuration('')
                }
            }

        }
    }


    const helper = (isActive: boolean,
                    setIsActive: (isActive: boolean) => void,
                    mediaType: MediaTypes,
                    droppedItems: any,
                    setDroppedItems: any) => {
        return <div className={
                cn("text", 
                    "mediaItem", 
                    (isActive) && "activeMedia", 
                    (mediaType === MediaTypes.VIDEO && (isUploadingInProgress[2] || (savedVideo && savedVideo.status === OrderMediaStatuses.IN_PROGRESS))) && "activeMedia")}>
            <div className={"itemContent-wrapper"}
                 onClick={() => setIsActive(true)}>
                <div className={"itemContent"}>
                    <img src={mediaType === MediaTypes.TEXT ? ((isActive) ? textIconWhite : textIcon)
                            : mediaType === MediaTypes.GIF ? ((isActive) ? gifIconWhite : gifIcon)
                            : mediaType === MediaTypes.PHOTO ? ((isActive) ? photoIconWhite : photoIcon)
                            : ((isActive || (isUploadingInProgress[2] || (savedVideo && savedVideo.status === OrderMediaStatuses.IN_PROGRESS)))
                                ? videoIconWhite : videoIcon)} 
                        alt="media-icon"/>
                    <p>{mediaType === MediaTypes.PHOTO ? 'Přidat foto' : 'Přidat ' + mediaType}</p>
                </div>
            </div>
            {((isActive) || (mediaType === MediaTypes.VIDEO && (isUploadingInProgress[2] || (savedVideo && savedVideo.status === OrderMediaStatuses.IN_PROGRESS)))) &&
            <div className={"closeWrapper"} onClick={() => {
                setIsActive(false)

                if ((orderRichTextObj && orderRichTextObj.length > 0) || uploadedPhotos.length > 0 || uploadedVideo || uploadedGifs.length > 0) {
                    switch (mediaType) {
                        case MediaTypes.TEXT:
                            if (orderRichTextObj && orderRichTextObj.length > 0) {
                                dispatch(deleteAttachment(orderRichTextObj[0].orderId, orderRichTextObj[0].id, AttachmentTypes.RICH_TEXT, MediaTypes.TEXT))
                            }
                            break
                        case MediaTypes.PHOTO:
                            if (uploadedPhotos.length > 0) {
                                uploadedPhotos.forEach((item) => {
                                    dispatch(deleteAttachment(item.orderId, item.id, AttachmentTypes.MEDIA, MediaTypes.PHOTO))
                                })
                            }
                            break
                        case MediaTypes.VIDEO:
                            if (uploadedVideo) {
                                dispatch(deleteAttachment(uploadedVideo.orderId, uploadedVideo.id, AttachmentTypes.MEDIA, MediaTypes.VIDEO))
                            }
                            break
                        case MediaTypes.GIF:
                            if (uploadedGifs.length > 0) {
                                uploadedGifs.forEach((item) => {
                                    dispatch(deleteAttachment(item.orderId, item.id, AttachmentTypes.MEDIA, MediaTypes.GIF))
                                })
                            }
                            break
                    }
                }

                setDroppedItems({
                    ...droppedItems.uploadedFiles,
                    uploadedFiles: []
                })
            }}><CloseOutlined/></div>
            }
        </div>
    }

    const onPhotosDrop = (acceptedFiles: File[]) => {
        if (photos.uploadedFiles.length + uploadedPhotos.length + acceptedFiles.length <= FilesLimitations.PHOTO_LIMITATION) {

            if (acceptedFiles.some((file, index) => file.size > photoMaxSize * bytesInMb)) {
                NotificationWarning('Chyba', `Maximální velikost obrázku je ${photoMaxSize} Mb`)
            }

            const files = acceptedFiles.map((file) => {
                if (file.size <= photoMaxSize * bytesInMb) {
                    return file
                }
            })

            const filteredFiles = files.filter((file) => (file !== undefined))
            if (filteredFiles && filteredFiles.length > 0) {
                setPhotos({
                    ...photos,
                    uploadedFiles: [
                        ...photos.uploadedFiles,
                        ...filteredFiles
                    ]
                })
            }

        } else {
            NotificationWarning('Maximální počet souborů byl přesažen', `Můžete nahrát maximálně ${FilesLimitations.PHOTO_LIMITATION} fotek`)
        }
    }

    useEffect(() => {
        if (videos.uploadedFiles.length > 0) {
            setVideoInfo()
        }
    }, [videos.uploadedFiles]);


    const onVideosDrop = (acceptedFiles: File[]) => {
        if(acceptedFiles.length > 0) {
            if (videos.uploadedFiles.length + acceptedFiles.length <= FilesLimitations.VIDEO_LIMITATION
                && !checkYoutubeVideoURL(youtubeLink) && !uploadedVideo) {
                    
                if (acceptedFiles[0].size <= videoMaxSize * bytesInMb) {
                    setVideos({
                        ...videos,
                        uploadedFiles: [
                            ...videos.uploadedFiles,
                            acceptedFiles[0]
                        ]
                    })
                } else {
                    NotificationWarning('Chyba', `Maximální velikost videa je ${videoMaxSize} Mb`)
                }
            } else {
                NotificationWarning('Maximální počet souborů byl přesažen', 'Můžete nahrát pouze 1 video, nebo přidat 1 odkaz')
            }
        }
    }

    const onGifsDrop = (acceptedFiles: File[]) => {
        if (gifs.uploadedFiles.length + uploadedGifs.length + acceptedFiles.length <= FilesLimitations.GIF_LIMITATION) {
            if (acceptedFiles.some((file) => file.size > gifMaxSize * bytesInMb)) {
                NotificationWarning('Chyba', `Maximální velikost gifu je ${gifMaxSize} Mb`)
            }

            const files = acceptedFiles.map((file) => {
                if (file.size <= gifMaxSize * bytesInMb) {
                    return file
                }
            })

            const filteredFiles = files.filter((file) => (file !== undefined))
            if (filteredFiles && filteredFiles.length > 0) {
                setGifs({
                    ...gifs,
                    uploadedFiles: [
                        ...gifs.uploadedFiles,
                        ...filteredFiles
                    ]
                })
            }

        } else {
            NotificationWarning('Maximální počet souborů byl přesažen', `Můžete nahrát pouze maximálně ${FilesLimitations.GIF_LIMITATION} gifů`)
        }
    }

    const savedVideo: any = mediaAttachments && mediaAttachments.filter((item) => item.type === MediaTypes.VIDEO)[0]
    const savedPhotos = mediaAttachments && mediaAttachments.filter((item) => item.type === MediaTypes.PHOTO)
    const savedGifs = mediaAttachments && mediaAttachments.filter((item) => item.type === MediaTypes.GIF)

    return <>
        <div className={"uploadComponents"}>
            <div className={"mediaCards"}>
                {helper(isTextActive, setIsTextActive, MediaTypes.TEXT, editorState, () => {})}
                {helper(isPhotoActive, setIsPhotoActive, MediaTypes.PHOTO, photos, setPhotos)}
                {helper(isVideoActive, setIsVideoActive, MediaTypes.VIDEO, videos, setVideos)}
                {helper(isGifActive, setIsGifActive, MediaTypes.GIF, gifs, setGifs)}
            </div>

            <div className={"mediaComponents"}>
                {isTextActive &&
                <div className={cn("textComponent", "mediaComponent")}>
                    <div className={"componentInfo"}>
                        <h2>Text</h2>
                        <p>Některé věci je snadnější napsat, než říct. Využij příležitosti a napiš sem všechno, co máš na srdci.</p>
                    </div>
                    <div className={"textEditorWrapper"}>
                        <div className={"editorStateLength"}>
                            <p className={"numbers"}>
                                <span>{editorState?.getCurrentContent().getPlainText('').length}</span>/{process.env.REACT_APP_EDITOR_STATE_MAX_LENGTH}
                            </p>
                        </div>
                        <Editor
                            editorState={editorState}
                            toolbarClassName={"toolbar"}
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorText"
                            onEditorStateChange={(state) => onEditorStateChange(editorState, setEditorState, state)}
                            toolbar={toolbar}
                        />
                    </div>
                </div>
                }

                {isPhotoActive &&
                <div className={cn("photoComponent", "mediaComponent")}>
                    <div className={"componentInfo"}>
                        <h2>Foto</h2>
                        <p>Přidej sem třeba vaši společnou fotku, nebo jakoukoliv jinou, která tvému blízkému vykouzlí úsměv na rtech. Maximální velikost fotky je {process.env.REACT_APP_PHOTO_MAX_SIZE_MB} MB, nahrát jich můžeš až {FilesLimitations.PHOTO_LIMITATION}.</p>
                    </div>

                    <div className={"fileUploadWrapper"}>
                        <div className={"fileUploadControls"}>
                            {isUploadingInProgress[1] ||
                            savedPhotos.some((item) => item.status === OrderMediaStatuses.IN_PROGRESS)
                                ? <div className={"loadingOverlay"}><LoadingOutlined/></div>
                                : <>
                                    <FileUpload accept={'.jpeg, .jpg, .png'}
                                        savedFiles={uploadedPhotos}
                                        onDrop={onPhotosDrop} files={photos}
                                        setFiles={setPhotos}
                                        placeholder={`Nahraj, nebo sem přetáhni maximálně ${FilesLimitations.PHOTO_LIMITATION} fotek, které chceš použít.`}
                                    />

                                    <div className={"cameraOpenControls"}>
                                        <CameraOpen onDrop={onPhotosDrop} type={CameraOpenTypes.PHOTO}/>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                }

                {(isVideoActive || (isUploadingInProgress[2] || (savedVideo && savedVideo.status === OrderMediaStatuses.IN_PROGRESS))) &&
                <div className={cn("videoComponent", "mediaComponent")}>
                    <div className={"componentInfo"}>
                        <h2>Video</h2>
                        <p>Buď se svým blízkým i v okamžiku, kdy mu nemůžeš dárek předat do vlastních rukou. Popřej mu osobně prostřednictvím videa. Protože naše přání není nafukovací, je potřeba, aby se vešlo do {process.env.REACT_APP_VIDEO_MAX_SIZE_MB} MB. Video vkládejte ve formátech: mp4, mov, avi, mkv a webm.</p>
                    </div>
                    {(isSendFilesTriggered && !isVideoUploaded) && <Loader/>}
                        {isUploadingInProgress[2] ? <FileUploadProgress uploadState={isUploadingInProgress[2]} mediaType={MediaTypes.VIDEO} /> :
                        videos.uploadedFiles.length > 0 || (uploadedVideo && uploadedVideo.url)
                            ? <div className={"uploadedVideo"}>
                                <div className={"videoWrapper"}>
                                    {uploadedVideo
                                        ? (uploadedVideo.url.includes('youtube')
                                            ? <YoutubeEmbed url={uploadedVideo.url}/>
                                            : <VideoPlayer url={uploadedVideo.streamingUrl} />)
                                        : <video controls className={"videoItem"}>
                                            <source src={URL.createObjectURL(videos.uploadedFiles[0])}/>
                                        </video>
                                    }
                                    <div className={"videoInfo"}>
                                        <p className={"videoName"}>
                                            {uploadedVideo
                                                ? (uploadedVideo.url.includes('youtube')
                                                    ? <a href={uploadedVideo.url}
                                                         target={"_blank"}>{(uploadedVideo.url && uploadedVideo.url.length > maxVideoTitleLength)
                                                        ? (uploadedVideo.url.substring(0, maxVideoTitleLength) + '...')
                                                        : uploadedVideo.url}
                                                    </a>
                                                    : uploadedVideo.url && getUploadedVideoTitle(uploadedVideo.url))
                                                : (videos.uploadedFiles[0].name && videos.uploadedFiles[0].name.length > maxVideoTitleLength)
                                                    ? (videos.uploadedFiles[0].name.substring(0, maxVideoTitleLength) + '...')
                                                    : videos.uploadedFiles[0].name
                                            }
                                        </p>
                                        <p className={"videoDuration"}>{videoDuration}</p>
                                    </div>
                                </div>
                                <span className={"closeIcon"}
                                      onClick={(e) => {
                                          uploadedVideo
                                              ? handleSavedFileDelete(uploadedVideo.orderId, uploadedVideo.id, AttachmentTypes.YOUTUBE_LINK, uploadedVideo.type, dispatch)
                                              : handleFileClose(setVideos, videos, videos.uploadedFiles[0], e)
                                      }
                                      }
                                >
                                    <CloseCircleOutlined/>
                                </span>
                            </div>
                            : <>
                                <div className={"fileUploadWrapper"}>
                                    <div className={"fileUploadControls"}>
                                        <FileUpload accept={'.mp4,.mov,.avi,.mkv,.webm'}
                                            savedFiles={uploadedVideo}
                                            onDrop={onVideosDrop}
                                            files={videos}
                                            setFiles={setVideos}
                                            placeholder={`Nahraj, nebo sem přetáhni video o maximální velikosti ${process.env.REACT_APP_VIDEO_MAX_SIZE_MB} MB.`}
                                        />

                                        <div className={"cameraOpenControls"}>
                                            <CameraOpen onDrop={onVideosDrop} type={CameraOpenTypes.VIDEO}/>
                                        </div>
                                    </div>
                                </div>
                                <Input
                                    disabled={(videos.uploadedFiles.length > 0 || (uploadedVideo && uploadedVideo.url)) ? true : false}
                                    onChange={(e) => setYoutubeLink(e.target.value)}
                                    size="large" className={"youtubeLink"}
                                    placeholder="Vložte odkaz na původní video nebo YouTube" prefix={<PaperClipOutlined/>}/>
                            </>
                    }
                </div>
                }

                {isGifActive &&
                <div className={cn("gifComponent", "mediaComponent")}>
                    <div className={"componentInfo"}>
                        <h2>Gif</h2>
                        <p>Má obdarovaný smysl pro humor? Nahraj mu sem GIF, který všechno řekne za tebe.</p>
                    </div>
                    {savedPhotos.some((item) => item.status === OrderMediaStatuses.IN_PROGRESS)
                        ? <div className={"loadingOverlay"}><LoadingOutlined/></div>
                        : <FileUpload savedFiles={uploadedGifs} accept={'.gif'} onDrop={onGifsDrop} files={gifs}
                            setFiles={setGifs} placeholder={`Nahraj, nebo sem přetáhni maximálně ${FilesLimitations.GIF_LIMITATION} gifů`}/>
                    }
                </div>
                }
            </div>

        </div>
    </>
};
