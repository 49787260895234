import React, {useEffect, useState} from 'react';
import styles from './Template.module.scss'
import {AdminHeader} from "../admin/AdminHeader/AdminHeader";
import {History} from "history";
import {useHistory} from "react-router";
import {isAuthenticate} from "../../utils/protectedRoute";
import {Header} from "../user/Header/Header";
import {Footer} from "../user/Footer/Footer";
import cn from "classnames";

type TemplateProps = {
    children: JSX.Element
}
 

export const Template: React.FC<TemplateProps> = ({children}) => {
        const history: History = useHistory()
        const [currentPathname, setCurrentPathname] = useState('')

        useEffect(() => {
            setCurrentPathname(history.location.pathname)
        }, [history.location.pathname]);


        history.listen((location) => {
            setCurrentPathname(location.pathname)
        })

        return <>
            {isAuthenticate() && history.location.pathname.split('/')[1] === 'admin'
                ? <>
                    <AdminHeader/>
                    <div className={styles.adminContent}>
                        {children}
                    </div>
                </>
                : (currentPathname === '/signin' || history.location.pathname.includes('admin'))
                    ? <div className={styles.mainContent}>
                        {children}
                    </div>
                    : <>
                        <div className={styles.wrapper}>
                            <div className={styles.header}>
                                <Header/>
                            </div>
                            <div className={cn(styles.userContent, currentPathname !== '/' && styles.backgroundImg)}>
                                {children}
                            </div>
                            <div className={styles.footer}>
                            {
                                ((currentPathname !== '/success') && (currentPathname.split('/')[1] !== 'package-info')) &&
                                    <Footer/>
                            }
                            </div>
                        </div>
                    </>
            }


        </>
    }
;