import {OrderType} from "../models/Orders";

export enum OrderStatuses {
    IN_PROGRESS = 'InProgress',
    SAVED = 'Saved',
    LAUNCHED = 'Launched',
    SENT = 'Sent',
    DELETED = 'Deleted'
}

export enum TranslatedOrderStatuses {
    IN_PROGRESS = 'Rozpracovaná',
    SAVED = 'Uložená',
    LAUNCHED = 'Zahájená',
    SENT = 'Odeslaná',
    DELETED = 'Smazaná'
}

export const orderStatuses = ['InProgress', 'Saved', 'Launched', 'Sent', 'Deleted']


const returnStatus = (status: string) => {
    if (status === OrderStatuses.SAVED) {
        return TranslatedOrderStatuses.SAVED
    } else if (status === OrderStatuses.DELETED) {
        return TranslatedOrderStatuses.DELETED
    } else if (status === OrderStatuses.SENT) {
        return TranslatedOrderStatuses.SENT
    } else if (status === OrderStatuses.IN_PROGRESS) {
        return TranslatedOrderStatuses.IN_PROGRESS
    } else if (status === OrderStatuses.LAUNCHED) {
        return TranslatedOrderStatuses.LAUNCHED
    }

    if (status === TranslatedOrderStatuses.SAVED) {
        return OrderStatuses.SAVED
    } else if (status === TranslatedOrderStatuses.DELETED) {
        return OrderStatuses.DELETED
    } else if (status === TranslatedOrderStatuses.SENT) {
        return OrderStatuses.SENT
    } else if (status === TranslatedOrderStatuses.IN_PROGRESS) {
        return OrderStatuses.IN_PROGRESS
    } else if (status === TranslatedOrderStatuses.LAUNCHED) {
        return OrderStatuses.LAUNCHED
    }
}

export const getTranslatedStatuses = (status: string | string[]) => {
    if (Array.isArray(status)) {
        return status.map((status: string) => {
            return returnStatus(status)
        })
    } else {
        return returnStatus(status)
    }
}

export type IsModalVisibleItem = {
    id?: string,
    isVisible?: boolean
}

export type TableColumnItemProps = {
    order: OrderType
}