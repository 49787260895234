import React from 'react';
import {CheckOutlined, QrcodeOutlined} from "@ant-design/icons";
import QRCode from "qrcode.react";
import styles from "./GenerateQR.module.scss"

type GenerateQRProps = {
    isGenerated: boolean;
    setIsGenerated: (isQrGenerated: boolean) => void;
    sendFiles?: (triggeredFrom: 'QR' | 'Button') => void;
    getIsButtonDisabled?: () => boolean;
}

export const GenerateQR: React.FC<GenerateQRProps> = ({isGenerated, setIsGenerated, getIsButtonDisabled, sendFiles}) => {

    //const [isQrGenerated, setIsQrGenerated] = useState(false)
/*
    const handleBtnClick = () => {
        setIsGenerated ? setIsGenerated(true) : setIsQrGenerated(true)
        getIsButtonDisabled && sendFiles && !getIsButtonDisabled() && sendFiles('QR')
    }*/
    const handleBtnClick = () => {
        setIsGenerated(true)
        getIsButtonDisabled && sendFiles && !getIsButtonDisabled() && sendFiles('QR')
    }
    return <div className={styles.qrWrapper}>
        <h2>Nesedíš zrovna u počítače? Nahraj svoje přání jednoduše z mobilu.</h2>
        <h2>Naskenujte QR kód a pokračujte ve svém telefonu</h2>
        <button disabled={isGenerated} onClick={handleBtnClick} className={styles.generateQR}>
            {(isGenerated || isGenerated) ? <p>Vygenerovano <CheckOutlined/></p> :
                <p>NAHRÁT PŘÁNÍ Z MOBILU <QrcodeOutlined/></p>}
        </button>
        
        <div className={styles.generatedQRContent} style={{display: (isGenerated || isGenerated) ? 'block' : 'none'}}>
            <QRCode className={"qr"} value={window.location.href}/>
            <p>Naskenujte kód pomoci kamery</p>
        </div>
    </div>
};
