import React, {useEffect, useState} from 'react';
import styles from "../../../pages/admin/Orders/Orders.module.scss";
import {DatePicker, Input, Select} from "antd";
import {getProperTag} from "../../../pages/admin/Orders/Orders";
import {useDispatch} from "react-redux";
import dayjs from "dayjs";
import {NotificationError} from "../../../utils/notifications";
import {ordersFilter} from "../../../store/actions/Filters";
import {getOrders} from "../../../store/reducers/admin/adminPanelReducer";
import {getTranslatedStatuses, orderStatuses} from "../../../utils/adminTable";

const {Search} = Input;

type FilterProps = {
    selectedPageSize: number | undefined;
    selectedPageNumber: number;
    dateFrom: any;
    setDateFrom: any;
    dateTo: any;
    setDateTo: any;
    selectedStatuses: any;
    setSelectedStatuses: any;
    translatedStatuses: any;
    setTranslatedStatuses: any;
    orderNumber: any;
    setOrderNumber: any;
    sortBy: string;
    sortType: string;
}

export const Filters: React.FC<FilterProps> = ({selectedPageNumber, orderNumber, setOrderNumber, setSelectedStatuses,
                                                   setDateTo, setDateFrom, dateFrom, dateTo, setTranslatedStatuses, translatedStatuses,
                                                   selectedStatuses, selectedPageSize, sortBy, sortType}) => {

    const dispatch = useDispatch()


    useEffect(() => {
        setTranslatedStatuses(getTranslatedStatuses(selectedStatuses) as string[])
    }, [selectedStatuses]);


    const [isMounted, setIsMounted] = useState(false);

    const dateFromPickerOnChange = (date: any, dateString: string) => {
        setDateFrom(dateString);
    }

    const dateToPickerOnChange = (date: any, dateString: string) => {
        setDateTo(dateString);
    }


    useEffect(() => {
        setIsMounted(true)
        if (((dateFrom && dateTo) || (!dateFrom && !dateTo)) && isMounted) {
            if (dateFrom && dateTo) {
                if (dayjs(dateFrom).unix() < dayjs(dateTo).unix()) {
                    dispatch(ordersFilter(translatedStatuses, dateFrom, dateTo, orderNumber, selectedPageSize, selectedPageNumber, sortBy, sortType))
                } else {
                    NotificationError('Neplatná data', 'První datum by mělo být dřívější než druhé')
                }
            } else {
                dispatch(ordersFilter(translatedStatuses, dateFrom, dateTo, orderNumber, selectedPageSize, selectedPageNumber, sortBy, sortType))
            }
        }
    }, [dateFrom, dateTo]);


    const handleStatusSelectChange = (statuses: string[]) => {
        if (statuses.length > 0) {
            setSelectedStatuses(statuses)
            dispatch(ordersFilter(
                getTranslatedStatuses(statuses) as any, 
                dateFrom, dateTo, orderNumber, selectedPageSize, selectedPageNumber, sortBy, sortType
            ))
        } else {
            setSelectedStatuses([])
            dispatch(ordersFilter(
                getTranslatedStatuses(statuses) as any, 
                dateFrom, dateTo, orderNumber, selectedPageSize, selectedPageNumber, sortBy, sortType
            ))
        }
    }

    const onOrderNumberSearch = (orderNumber: string) => {
        setOrderNumber(orderNumber)
        dispatch(ordersFilter(
            translatedStatuses, dateFrom, dateTo, orderNumber, selectedPageSize, selectedPageNumber, sortBy, sortType
        ))
    }

    function tagRender(props: any): any {
        const {label, value, closable, onClose} = props;
        return getProperTag(label)
    }

    return (
        <div className={styles.filters}>
            <div className={styles.daysRangeFilter}>
                <div className={styles.date}><DatePicker placeholder='Ode dne' onChange={dateFromPickerOnChange}/></div>
                <div className={styles.line}></div>
                <div className={styles.date}><DatePicker placeholder='Ke dni' onChange={dateToPickerOnChange}/></div>
            </div>
            <div className={styles.statusFilter}>
                <Select
                    mode="multiple"
                    showArrow
                    onChange={handleStatusSelectChange}
                    placeholder='Vybrat stav'
                    tagRender={tagRender}
                    options={orderStatuses.map((status: string, index: number) => ({
                        value: getTranslatedStatuses(status) as any,
                        key: index
                    }))}
                />
            </div>
            <div className={styles.orderNumberFilter}>
                <Search placeholder="Zadejte číslo objednávky" onSearch={onOrderNumberSearch} enterButton/>
            </div>
        </div>
    );
};