import React, {useEffect, useState} from 'react';
import { Steps } from 'antd';
import { LeftOutlined, } from "@ant-design/icons";
import {FirstStep} from "../../../components/user/CreatePackage/FirstStep/FirstStep";
import "./CreatePackage.scss"
import {SecondStep} from "../../../components/user/CreatePackage/SecondStep/SecondStep";
import {getOrderInfo} from "../../../store/reducers/user/packageReducer";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {History} from "history";
import {NotificationError, NotificationWarning} from "../../../utils/notifications";
import {ThirdStep} from "../../../components/user/CreatePackage/ThirdStep/ThirdStep";
import {getOrderStatus} from "../../../store/selectors/packageSelectors";
import {OrderStatuses} from "../../../utils/adminTable";

const {Step} = Steps;

export type StepProps = {
    setCurrent: (current: number) => void;
}

export const CreatePackage = () => {

    const dispatch = useDispatch()
    const history: History = useHistory()

    const orderStatus = useSelector(getOrderStatus)

    const [currentStep, setCurrentStep] = useState(0);

    const orderId = history.location.pathname.split('/')[2] || sessionStorage.getItem('orderId')
    //const orderNumber = sessionStorage.getItem('orderNumber')

    const setStep = (step: number) => {
        sessionStorage.setItem('currentStep', `${step}`)
        setCurrentStep(step)
    }

    useEffect(() => {
        window.scroll(0, 0)

        if (orderStatus === OrderStatuses.SENT || orderStatus === OrderStatuses.DELETED || orderStatus === OrderStatuses.SAVED) {
            history.push(`/success`)
        }

        if (sessionStorage.getItem('currentStep')) {
            // @ts-ignore
            setCurrentStep(+sessionStorage.getItem('currentStep'))
        }
    }, []);
    useEffect(() => {
        if (orderStatus === OrderStatuses.SENT || orderStatus === OrderStatuses.DELETED || orderStatus === OrderStatuses.SAVED) {
            history.push(`/success`)
        }
    }, [orderStatus]);

    useEffect(() => {
            if (!orderId || orderId === "undefined") {
                NotificationError('Chyba', 'Číslo objednávky již existuje')
                history.push('/')
            } else {
                dispatch(getOrderInfo('', orderId))
            }
    }, [orderId]);


    const [isGoToRiddleTriggered, setIsGoToRiddleTriggered] = useState(false)
    const [isVideoUploadTriggered, setIsVideoUploadTriggered] = useState(false)

    const steps = [
        {
            title: currentStep > 0 ? <button onClick={() => setStep(0)}>Vzkaz</button> : 'Vzkaz',
            content: <FirstStep isVideoUploadTriggered={isVideoUploadTriggered} setIsVideoUploadTriggered={setIsVideoUploadTriggered}
                                setIsGoToRiddleTriggered={setIsGoToRiddleTriggered}
                                setCurrent={setStep}/>,
            id: 0
        },
        // {
        //     title: currentStep > 1 ? <button onClick={() => setStep(1)}>Hadanka</button> : 'Hadanka',
        //     content: <SecondStep setCurrent={setStep}/>,
        //     id: 1
        // },
        {
            title: currentStep > 2 ? <button onClick={() => setStep(2)}>Náhled</button> : 'Náhled',
            content: <ThirdStep isVideoUploadTriggered={isVideoUploadTriggered}
                                isGoToRiddleTriggered={isGoToRiddleTriggered}
                                setCurrent={setStep}/>,
            id: 2
        },
    ];

    const getCurrentStep = () => {
        return steps.find(step => step.id === currentStep)?.content ?? <></>;
    }

    return <>
        <div className={"steps"}>
          
            <Steps current={currentStep}>
                {steps.map(item => (
                    <Step key={item.id} title={item.title}/>
                ))}
            </Steps>

              {/* { (currentStep > 0) &&  <button className={"goBack"} onClick={() => setStep(0)}><LeftOutlined/>
                <span className={"text"}>Zpátky na editaci</span></button>} */}
            
        </div>
        <div className="steps-content">{getCurrentStep()}</div>
    </>
};