import React from 'react';
import {useDropzone} from "react-dropzone";
import "./FileUpload.scss"
import {CloseCircleOutlined, FileAddOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {deleteAttachment} from "../../../../store/reducers/user/packageReducer";
import {AttachmentTypes} from "../../CreatePackage/FirstStep/FirstStep";
import {SavedMediaAttachmentType} from "../../../../models/Package";
import { MediaTypes } from '../../../../helpers/MediaInfo';


type FileUploadProps = {
    onDrop: any,
    files: { uploadedFiles: File[] },
    setFiles: any,
    placeholder: string,
    accept: string,
    savedFiles: SavedMediaAttachmentType[] | SavedMediaAttachmentType
}

export const handleFileClose = (setFiles: any, files: { uploadedFiles: File[] }, file: File, e: any) => {
    setFiles({
        ...files,
        uploadedFiles: files.uploadedFiles.filter((f: File) => (f.name !== file.name && f.lastModified !== file.lastModified))
    })
    e.stopPropagation()
    e.preventDefault()
}

export const handleSavedFileDelete = (orderId: string, attachmentId: string, attachmentType: AttachmentTypes, mediaType: MediaTypes, dispatch: any) => {
    dispatch(deleteAttachment(orderId, attachmentId, attachmentType, mediaType))
}

export const FileUpload: React.FC<FileUploadProps> = ({
                                                                                  savedFiles,
                                                                                  onDrop,
                                                                                  files,
                                                                                  setFiles,
                                                                                  accept,
                                                                                  placeholder
                                                                              }) => {

    const dispatch = useDispatch()

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept
    });

    return <div className={"uploadMediaWrapper"}>
        <div {...getRootProps()}>
            <input className="dropzone-input" {...getInputProps()} />
            <div className="fileUploadWrapper">
                <div className="content">
                    <p className="dropzone-content">
                        <p> {placeholder} <span><FileAddOutlined/></span></p>
                    </p>
                </div>
            </div>
        </div>

        <div className={"uploadedFiles"}>
            {files.uploadedFiles.length > 0 && Array.from(files.uploadedFiles).map((file: any, index) => {
                return <div key={file.name} className={"uploadedFile"}>
                    <img src={file.name ? URL.createObjectURL(file) : file} alt={'uploaded-file'}/>
                    <span className={"closeIcon"}
                          onClick={(e) => handleFileClose(setFiles, files, file, e)}
                    >
                        <CloseCircleOutlined/>
                    </span>
                </div>
            })}
            {savedFiles && Array.isArray(savedFiles) && savedFiles.length > 0 && Array.from(savedFiles).map((file: SavedMediaAttachmentType) => {
                return <div key={file.id} className={"uploadedFile"}>
                    <img src={file.url} alt={'saved-file'}/>
                    <span className={"closeIcon"}
                          onClick={(e) => handleSavedFileDelete(file.orderId, file.id, AttachmentTypes.MEDIA, file.type, dispatch)}
                    >
                        <CloseCircleOutlined/>
                    </span>
                </div>
            })}
        </div>
    </div>
};