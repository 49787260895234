import {OrderStatuses} from "../utils/adminTable";
import {MediaTypes} from "../helpers/MediaInfo";



export enum OrderMediaStatuses {
    IN_PROGRESS = 'InProgress',
    COMPLETED = 'Completed',
    ERROR = 'Error'
}


export type SavedMediaAttachmentType = {
    id: string;
    orderId: string;
    type: MediaTypes;
    url: string;
    streamingUrl: string;
    status: OrderMediaStatuses
}

export type SavedRichTextType = {
    id: string;
    orderId: string;
    value: string;
}

export type RiddleType = {
    id: string;
    isPredefined: boolean;
    question: string;
    answer: string;
}

export type PackageType = {
    id: string,
    number: number,
    code: number
    status: OrderStatuses
    startedDate: string,
    savedDate: string,
    sentDate: string,
    link: string,
    mediaAttachments: SavedMediaAttachmentType[],
    richTextAttachments: SavedRichTextType[],
    riddle: RiddleType
}