import {BaseThunkType, InferActionsType} from "../../store";
import {authService} from "../../../api/authService";
import {SET_AUTHED_USER_DATA} from "../../types";
import {ResponseStatuses} from "../../../api/axiosInstance";
import {NotificationError} from "../../../utils/notifications";
import {loaderActions} from "../loaderReducer";
import {History} from "history";


let initialState = {
    user: {} as any
};


export const authReducer = (state = initialState, action: ActionsTypes): InitialStateType => {
    switch (action.type) {
        case SET_AUTHED_USER_DATA:
            return {
                ...state,
                user: action.payload.user
            }
        default:
            return state;
    }
}


export const authActions = {
    setAuthedUserData: (user: any) => ({
        type: SET_AUTHED_USER_DATA,
        payload: {user}
    } as const),
}

export const loginUser = (login: string,
                          password: string,
                          rememberMe: boolean,
                          resetForm: () => void,
                          setSubmitting: (isSubmitting: boolean) => void,
                          history: History): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(true))

            let data = await authService.login(login, password);

            if (data.status === ResponseStatuses.SUCCESS) {
                dispatch(authActions.setAuthedUserData(data.data.user));

                if (rememberMe) {
                    localStorage.setItem('token', data.data.token)
                    localStorage.setItem('validTo', data.data.validTo)
                    localStorage.setItem('username', data.data.user.login)
                } else {
                    sessionStorage.setItem('token', data.data.token)
                    sessionStorage.setItem('validTo', data.data.validTo)
                    sessionStorage.setItem('username', data.data.user.login)
                }

                history.push('/admin')
                //resetForm()
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            setSubmitting(false)
            // @ts-ignore
            dispatch(loaderActions.handleLoader(false))
        }
    }

export const logoutUser = () => (dispatch: any) => {
    try {
        // @ts-ignore
        dispatch(loaderActions.handleLoader(true))

        dispatch(authActions.setAuthedUserData({}))
        localStorage.clear()
        sessionStorage.clear()

        /*history.push('/signin')*/
    } catch (e) {
        NotificationError('Chyba', 'Vyskytla se chyba')
    } finally {
        // @ts-ignore
        dispatch(loaderActions.handleLoader(false))
    }
}

type ActionsTypes = InferActionsType<typeof authActions>
export type InitialStateType = typeof initialState
type ThunkType = BaseThunkType<ActionsTypes>