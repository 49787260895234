import {Button} from "antd";
import cn from "classnames";
import {History} from "history";
import {Link} from 'react-router-dom';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import styles from './AlmostDone.module.scss'
import {HashLink} from 'react-router-hash-link';
import {useHistory} from "react-router";
import {DownOutlined, RightOutlined} from "@ant-design/icons";
import {getOrderId, getOrderStatus} from "../../../../store/selectors/packageSelectors";
import {OrderStatuses} from "../../../../utils/adminTable";

type AlmostDoneProps = {
    orderId: string
}

export const AlmostDone: React.FC<AlmostDoneProps> = ({orderId}) => {
    const history:History = useHistory()
    const orderStatus = useSelector(getOrderStatus)
    const orderid = useSelector(getOrderId);
    
    useEffect(() => {
        console.log(orderStatus)
        if(orderid && (orderStatus === OrderStatuses.SAVED || orderStatus === OrderStatuses.SENT)) {
            history.push({
                pathname:`package-info/${orderid}`,
                state: 'asd'
            })
        }
    }, [orderStatus, orderid])

    
    return (
        <div className={cn(styles.wrapper, styles.almostDoneWrapper)}>
            <div className={styles.content}>
                <h1>#Gifitstory ti pomůže dát dárku duši</h1>
                <p>Gratulujeme, brzy někomu uděláš velkou radost! Přiveď svůj dárek k dokonalosti a přidej k němu osobní přání, které obdarovaného zahřeje u srdce. Nebo ho třeba rozesměje. To už necháme na tobě &#128522;</p>
                <Link to={`/create-package/${orderId}`}><Button type={"primary"}>PŘIDAT DÁRKU DUŠI<RightOutlined/></Button></Link>

                {/*<HashLink to='#HowDoesItWork'>
                    <div className={styles.scrollDown}>
                        <DownOutlined/>
                    </div>
                </HashLink>*/}
            </div>
        </div>
    );
};