import {ContentState, EditorState} from "draft-js";

export const onEditorStateChange = (editorState: EditorState | undefined,
                                    setEditorState: (state: EditorState) => void,
                                    state: EditorState) => {

    if (editorState) {
        const contentState = state.getCurrentContent();
        const oldContent = editorState.getCurrentContent();
        // @ts-ignore
        if (contentState === oldContent || contentState.getPlainText().length <= +process.env.REACT_APP_EDITOR_STATE_MAX_LENGTH) {
            setEditorState(state);
        } else {
            const newEditorState = EditorState.moveFocusToEnd(
                EditorState.push(
                    editorState as EditorState,
                    ContentState.createFromText(oldContent.getPlainText()),
                    'delete-character'
                )
            );
            setEditorState(newEditorState);
        }
    }
}