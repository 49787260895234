import React, {useEffect, useState} from 'react';
import styles from "./CameraOpen.module.scss"
import {Button} from "antd";
import {CameraOutlined, VideoCameraOutlined} from "@ant-design/icons";
import {Photo} from "./Photo";
import {Video} from "./Video";
import useWindowDimensions from "../../../../helpers/getWindowDimensions";
import {NotificationWarning} from "../../../../utils/notifications";

export enum CameraOpenTypes {
    VIDEO = 'video',
    PHOTO = 'photo',
}

type CameraOpenProps = {
    type: CameraOpenTypes;
    onDrop: (files: File[]) => void;
}

const VideoConstraintsMissingWarning = () => {
    NotificationWarning('Varování', 'Vaše zařízení nepodporuje nahrávání videa.')
}

export const CameraOpen: React.FC<CameraOpenProps> = ({type, onDrop}) => {


    const [isTakePhotoModalVisible, setIsTakePhotoModalVisible] = useState(false);
    const [isTakeVideoModalVisible, setIsTakeVideoModalVisible] = useState(false);
    const [isVideoCancelTriggered, setIsVideoCancelTriggered] = useState(false)
    const [videoConstraints, setVideoConstraints] = useState({} as any)
    const [isVideoConstraintsMissing, setIsVideoConstraintsMissing] = useState(false)
    const {height, width} = useWindowDimensions();
    const [devicesCount, setDevicesCount] = useState(0);

    const isUserMediaSupported = typeof navigator.mediaDevices.getUserMedia !== "undefined";
    const isEnumerateDevicesSupported = typeof navigator.mediaDevices.enumerateDevices !== "undefined";
    const isMediaDevicesContstrainsSupported = typeof navigator.mediaDevices.getSupportedConstraints !== "undefined";
    const isMediaRecorderSupported = typeof MediaRecorder !== "undefined";

    let supports: any;
    if (isMediaDevicesContstrainsSupported) {
        supports = navigator.mediaDevices.getSupportedConstraints();
    }

    useEffect(() => {
        getDevicesCount();
    }, []);

    useEffect(() => {

        if (isMediaDevicesContstrainsSupported) {
            if (height / width > 1) {
                if (!supports["width"] || !supports["height"] || !supports["frameRate"] || !supports["facingMode"]) {
                    VideoConstraintsMissingWarning()
                    setIsVideoConstraintsMissing(true)
                } else {
                    setVideoConstraints({
                        width: {min: 480},
                        height: {min: 720},
                        aspectRatio: 0.6666666667
                        // width: {ideal: width - 10, max: 720},
                        // height: {min: height - 10, max: 1280},
                        // frameRate: {max: process.env.REACT_APP_VIDEO_FRAME_RATE}
                    })
                }
            } else {
                if (!supports["width"] || !supports["height"] || !supports["frameRate"]) {
                    VideoConstraintsMissingWarning()
                    setIsVideoConstraintsMissing(true)
                } else {
                    setVideoConstraints({
                        width: {ideal: width - 10, max: process.env.REACT_APP_DESKTOP_CAMERA_WIDTH},
                        height: {ideal: height - 10, max: process.env.REACT_APP_DESKTOP_CAMERA_HEIGHT},
                        frameRate: {max: process.env.REACT_APP_VIDEO_FRAME_RATE}
                    })
                }
            }
        }

    }, [width, height]);

    const handleVideoCameraClick = () => {
        setIsTakeVideoModalVisible(true)
        setIsVideoCancelTriggered(false)
    }

    const getDevicesCount = () => {
        if (isUserMediaSupported && isEnumerateDevicesSupported) {
            navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
                setDevicesCount(mediaDevices?.filter(({kind}: any) => kind === "videoinput")?.length ?? 0);
            });
        }
    }

    return <>
        <Photo videoConstraints={videoConstraints}
               onDrop={onDrop}
               isTakePhotoModalVisible={isTakePhotoModalVisible}
               setIsTakePhotoModalVisible={setIsTakePhotoModalVisible}
        />
        <Video videoConstraints={videoConstraints}
               isVideoCancelTriggered={isVideoCancelTriggered} setIsVideoCancelTriggered={setIsVideoCancelTriggered}
               isTakeVideoModalVisible={isTakeVideoModalVisible}
               setIsTakeVideoModalVisible={setIsTakeVideoModalVisible}
               onDrop={onDrop}
        />
        {isUserMediaSupported && devicesCount > 0
            && <>
                <div className={styles.cameraOpenWrapper}>

                    {type === CameraOpenTypes.PHOTO
                        ?
                        <>
                            <Button onClick={() => {
                                !isVideoConstraintsMissing ? setIsTakePhotoModalVisible(true) : VideoConstraintsMissingWarning()
                            }} type={"primary"}>
                                <CameraOutlined/>
                            </Button>
                            <p>Kliknutím použijete <b>kameru</b></p>
                        </>
                        : <>
                            <Button onClick={() => {
                                !isVideoConstraintsMissing ? handleVideoCameraClick() : VideoConstraintsMissingWarning()
                            }} type={"primary"}>
                                <VideoCameraOutlined/>
                            </Button>
                            <p>Kliknutím použijete <b>kameru</b></p>
                        </>
                    }
                </div>
            </>}
    </>
};