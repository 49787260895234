import React from 'react';
import {Redirect, Route} from 'react-router-dom';

export const ProtectedRoute = ({...rest}) => {
    return isAuthenticate() ? <Route {...rest} /> : <Redirect to="/signin" />;
};

export const SigninRoute = ({...rest}) => {
    return isAuthenticate() ? <Redirect to="/admin" /> : <Route {...rest} />
}

export const isAuthenticate = () => {
    return localStorage.getItem('token') || sessionStorage.getItem('token');
};