import {BaseThunkType, InferActionsType} from "../../store";
import {DELETE_ORDER, SET_FILTERS, SET_ORDERS_INFO, SET_SETTINGS, UPDATE_ORDERS_INFO} from "../../types";
import {ResponseStatuses} from "../../../api/axiosInstance";
import {NotificationError, NotificationSuccess} from "../../../utils/notifications";
import {loaderActions} from "../loaderReducer";
import {adminService} from "../../../api/adminService";
import {OrderType} from "../../../models/Orders";
import fileDownload from 'js-file-download';
import {ordersFilter} from "../../actions/Filters";
import {OrderStatuses} from "../../../utils/adminTable";


let initialState = {
    orders: [] as any,
    totalCount: 0 as number,
    currentFilters: [] as any,
    settings: [] as any,
};


export const adminPanelReducer = (state = initialState, action: ActionsTypes): InitialStateType => {
    switch (action.type) {
        case SET_ORDERS_INFO:
            return {
                ...state,
                orders: action.payload.orders,
                totalCount: action.payload.totalCount
            }
        case DELETE_ORDER:
            let orders = state.orders
            orders[state.orders.findIndex((order: OrderType) => order.id === action.payload.orderId)].status = OrderStatuses.DELETED

            return {
                ...state,
                orders: orders
            }
        case UPDATE_ORDERS_INFO:
            let newOrders = state.orders
            newOrders[state.orders.findIndex((order: OrderType) => order.id === action.payload.order.id)] = action.payload.order

            return {
                ...state,
                orders: newOrders
            }
        case SET_SETTINGS:
            return {
                ...state,
                settings: action.payload.data
            }
        default:
            return state;
    }
}


export const adminPanelActions = {
    setOrdersInfo: (data: any) => ({
        type: SET_ORDERS_INFO,
        payload: {orders: data.list, totalCount: data.totalCount}
    } as const),
    updateOrdersInfo: (order: any) => ({
        type: UPDATE_ORDERS_INFO,
        payload: {order}
    } as const),
    deleteOrder: (orderId: string) => ({
        type: DELETE_ORDER,
        payload: {orderId}
    } as const),
    setSettings: (data: any[]) => ({
        type: SET_SETTINGS,
        payload: {data}
    } as const),
}

export const getOrders = (pageSize: number = 10, pageNumber: number = 1): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(true))

            let data = await adminService.getOrdersInfo(pageSize, pageNumber);

            if (data.status === ResponseStatuses.SUCCESS) {
                dispatch(adminPanelActions.setOrdersInfo(data.data));
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(false))
        }
    }

export const sendOrder = (orderId: string, send: boolean): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleAdminTableLoader(true))

            let data = (send) ? await adminService.sendOrder(orderId) : await adminService.unsendOrder(orderId);

            if (data.status === ResponseStatuses.SUCCESS) {
                dispatch(adminPanelActions.updateOrdersInfo(data.data));
                NotificationSuccess('Povedlo se', 'Status byl úspěšně změněn')
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleAdminTableLoader(false))
        }
    }

export const changeLink = (orderId: string, link: string): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleAdminTableLoader(true))

            let data = await adminService.changeLink(orderId, link);

            if (data.status === ResponseStatuses.SUCCESS) {
                dispatch(adminPanelActions.updateOrdersInfo(data.data));
                NotificationSuccess('Povedlo se', 'Odkaz byl úspěšně změněn')
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleAdminTableLoader(false))
        }
    }

export const changeStatus = (orderId: string, status: string, setIsConfirmModalVisible:any): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleAdminTableLoader(true))

            let data = await adminService.changeOrderStatus(orderId, status);

            if (data.status === ResponseStatuses.SUCCESS) {
                dispatch(adminPanelActions.updateOrdersInfo(data.data));
                NotificationSuccess('Povedlo se', 'Status byl úspěšně změněn')
                setIsConfirmModalVisible({});
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleAdminTableLoader(false))
        }
    }

export const printQR = (orderId: string, orderNumber: number): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleAdminTableLoader(true))

            let res = await adminService.printOrderQR(orderId);

            if (res.status === 200) {
                fileDownload(res.data, `Order_${orderNumber}_QR.pdf`)
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleAdminTableLoader(false))
        }
    }

export const updateCode = (currentOrder: OrderType, orderId: string, code: number, setSubmitting: any, resetForm: any): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleAdminTableLoader(true))

            if (currentOrder.code !== code) {
                let data = await adminService.updateOrderCode(orderId, code);

                if (data.status === ResponseStatuses.SUCCESS) {
                    dispatch(adminPanelActions.updateOrdersInfo(data.data));
                    NotificationSuccess('Povedlo se', 'Kód byl úspěšně změněn')
                    resetForm()
                }
            } else {
                NotificationSuccess('Povedlo se', 'Kód byl úspěšně změněn')
                resetForm()
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            setSubmitting(false)
            // @ts-ignore
            dispatch(loaderActions.handleAdminTableLoader(false))
        }
    }

export const deleteOrderById = (orderId: string, setIsMoreActionsModalVisible: any, setIsConfirmModalVisible: any): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleAdminTableLoader(true))

            let data = await adminService.orderDelete(orderId);

            if (data.status === ResponseStatuses.SUCCESS) {
                setIsMoreActionsModalVisible({})
                setIsConfirmModalVisible({})
                NotificationSuccess('Povedlo se', 'Objednávka byla úspěšně smazána')
                dispatch(adminPanelActions.deleteOrder(orderId))
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleAdminTableLoader(false))
        }
    }

export const updateDefaultCode = (newCode: string, setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(true))

            let data = await adminService.updateOrdersDefaultCode(newCode);

            if (data.status === ResponseStatuses.SUCCESS) {
                //resetForm()
                NotificationSuccess('Povedlo se', 'Kód byl úspěšně změněn')
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            setSubmitting(false)
            // @ts-ignore
            dispatch(loaderActions.handleLoader(false))
        }
    }

    export const getSettings = (): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(true))

            let data = await adminService.getCurrentSettings();

            if (data.status === ResponseStatuses.SUCCESS) {
                dispatch(adminPanelActions.setSettings(data.data))
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(false))
        }
    }

type ActionsTypes = InferActionsType<typeof adminPanelActions>
export type InitialStateType = typeof initialState
export type ThunkType = BaseThunkType<ActionsTypes>