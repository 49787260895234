import React, {useEffect} from 'react';
import "./Success.scss"
import {CheckCircleOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {saveOrder} from "../../../store/reducers/user/packageReducer";
import {History} from "history";
import {useHistory} from "react-router";
import {getOrderStatus} from "../../../store/selectors/packageSelectors";
import {OrderStatuses} from "../../../utils/adminTable";
import {NotificationWarning} from "../../../utils/notifications";
import CSS from 'csstype';

export const Success = () => {
    const dispatch = useDispatch()

    const orderStatus = useSelector(getOrderStatus)

    const history: History = useHistory()
    const orderId = sessionStorage.getItem('orderId')

    useEffect(() => {
        if (orderStatus && orderStatus !== OrderStatuses.SENT && orderStatus !== OrderStatuses.DELETED && orderStatus !== OrderStatuses.SAVED) {
            if (orderId) {
                dispatch(saveOrder(orderId))
            } else {
                history.push('/')
                NotificationWarning('Varování', 'Číslo objednávky již existuje')
            }
        } else if(orderStatus === OrderStatuses.SENT || orderStatus === OrderStatuses.DELETED || orderStatus === OrderStatuses.SAVED){
            //stay on page
        } else {
            history.push('/')
        }

        sessionStorage.setItem('currentStep', '0')
    }, []);

    const style: CSS.Properties = {
        borderRadius: '10px',
        marginTop: '30px',
        padding: '10px',
        height: 'auto',
        width: 'auto'
    };

    return <div className={"successWrapper"}>
        <div className={"successContent"}>
            <div className={"checkIcon"}><CheckCircleOutlined/></div>
            <div className={"mainText"}>
                <h1>Hotovo!</h1>
                <p>
                Výborná práce! Právě jsi skvělý dárek povýšil na dokonalý. Originální přání mu dodá na osobitosti a zajistí, že na něj obdarovaný jen tak nezapomene. Přání teď skryjeme pod QR kód a přiložíme jako překvapení k boxu. A teď už jen stačí dostat dárek k obdarovanému.
                </p>
                <div className="goMain" >
                    <button onClick={() => window.location.replace("https://gifit.cz")}>
                    ZPĚT NA DOMOVSKOU STRÁNKU
                    </button>
                </div>
            </div>
        </div>
    </div>
};