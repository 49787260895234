import React, {useEffect, useState} from 'react';
import "./FAQ.scss"
import "../../../components/user/CreatePackage/SecondStep/SelectRiddle/SelectRiddle.scss"
import {useDispatch} from "react-redux";
import {getFAQInfo} from "../../../store/reducers/user/FAQReducer";
import {FAQModel} from "../../../models/FAQ";
import {DownOutlined, UpOutlined} from "@ant-design/icons";

export const FAQ = () => {

    useEffect(() => {
        sessionStorage.setItem('currentStep', '0')
    }, [])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getFAQInfo())
    }, []);

    //const FAQInfo = useSelector(getFAQs)

    const FAQInfo = [
        {id: '123', question: 'question1?', answer: 'answer1'},
        {id: '1234', question: 'question2?', answer: 'answer2'},
        {id: '1235', question: 'question3?', answer: 'answer3'}
    ] as FAQModel[]

    const [state, setState] = useState({
        openedRiddles: [] as boolean[]
    })

    return <div className={"faqWrapper"}>
        <h1>FAQ</h1>

        {FAQInfo && FAQInfo.length > 0 &&
        <div className={"faqItems"}>
            {FAQInfo.map((item, index) => {
                return <div key={item.id} className={"faqItem"}>
                    <div className={"question"} onClick={() => {
                        let newOpenedRiddles = state.openedRiddles
                        newOpenedRiddles[index] = !state.openedRiddles[index]

                        setState({
                            ...state,
                            openedRiddles: newOpenedRiddles
                        })
                    }}>
                        <p>{item.question} {!state.openedRiddles[index] ? <DownOutlined/> : <UpOutlined/>}</p>
                    </div>

                    <div className={"faqItemContent"}
                         style={{display: state.openedRiddles[index] ? 'flex' : 'none'}}>
                        <div className="answer">
                            <p>{item.answer}</p>
                        </div>
                    </div>
                </div>
            })}
        </div>
        }
    </div>
};