import React, {useEffect, useState} from "react";
import "./ThirdStep.scss";
import {DownloadOutlined, LeftOutlined, LoadingOutlined, RightOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {
    getIsRiddleUploadingInProgress,
    getIsUploadingInProgress,
    getIsVideoUploaded,
    getOrderCode,
    getOrderMedia,
    getOrderRichText,
    getOrderLink,
    getOrderRiddle,
    getOrderStatus,
} from "../../../../store/selectors/packageSelectors";
import * as Yup from "yup";
import {Markup} from "interweave";
import {Link} from "react-router-dom";
import FileSaver from "file-saver";

// @ts-ignore
import {AzureMP} from "react-azure-mp";
import {YoutubeEmbed} from "../../../../helpers/YoutubeEmbed/YoutubeEmbed";
import {MediaTypes} from "../../../../helpers/MediaInfo";
import {OrderStatuses} from "../../../../utils/adminTable";
import {History} from "history";
import {useHistory} from "react-router";
import ImageGallery from "react-image-gallery";
import {getCarouselMediaItems} from "../../../../helpers/getCarouselMedia";
import {OrderMediaStatuses} from "../../../../models/Package";
import {getOrderInfo, MediaUploadState} from "../../../../store/reducers/user/packageReducer";
import {NotificationError} from "../../../../utils/notifications";
import { FileUploadProgress } from "../../MediaInputs/FileUploadProgress/FileUploadProgress";
import { packageService } from "../../../../api/packageService";
import { ResponseStatuses } from "../../../../api/axiosInstance";

type ThirdStepProps = {
    isGoToRiddleTriggered?: boolean;
    isVideoUploadTriggered?: boolean;
    setCurrent?: (current: number) => void;
};

export const ThirdStep: React.FC<ThirdStepProps> = ({
                                                        setCurrent,
                                                        isGoToRiddleTriggered,
                                                        isVideoUploadTriggered,
                                                    }) => {
  useEffect(() => {
      window.scroll(0, 0);
  }, []);

  const isVideoUploaded = useSelector(getIsVideoUploaded);
  const mediaAttachments = useSelector(getOrderMedia);
  const orderRiddle = useSelector(getOrderRiddle);
  const orderCode = useSelector(getOrderCode);
  const orderLink = useSelector(getOrderLink);
  const orderRichText = useSelector(getOrderRichText);
  const isUploadingInProgress = useSelector(getIsUploadingInProgress);
  const isRiddleUploadingInProgress = useSelector(
      getIsRiddleUploadingInProgress
  );
  const orderStatus = useSelector(getOrderStatus);

  const history: History = useHistory();
  const dispatch = useDispatch();

  const [isAnswersEqual, setIsAnswersEqual] = useState(false);
  const [isSubmitTriggered, setIsSubmitTriggered] = useState(false);
  const [approved, setApproved] = useState(false);

  const uploadedVideo: any =
      mediaAttachments &&
      mediaAttachments.filter((item) => item.type === MediaTypes.VIDEO)[0];
  const uploadedPhotos =
      mediaAttachments &&
      mediaAttachments.filter((item) => item.type === MediaTypes.PHOTO);
  const uploadedGifs =
      mediaAttachments &&
      mediaAttachments.filter((item) => item.type === MediaTypes.GIF);

  const validationSchema = Yup.object().shape({
      answer: Yup.string().required(),
  });

  window.onbeforeunload = function () {
      if (isUploadingInProgress.some((isUploading) => isUploading)) {
          return "Data will be lost if you reload the page, are you sure?";
      }
  };

    const orderId = history.location.pathname.split("/")[2];

     useEffect(() => {
         if (setCurrent &&
             (orderStatus === OrderStatuses.SENT ||
             orderStatus === OrderStatuses.DELETED ||
             orderStatus === OrderStatuses.SAVED)
         ) {
             history.push(`/success`);
         }
     }, []);

    useEffect(() => {
        if (!orderCode && !orderId) {
          NotificationError("Chyba", "Neplatné číslo objednávky");
        }
    }, []);

  const [carouselImages, setCarouselImages] = useState([] as any);

  useEffect(() => {
      if (uploadedPhotos && uploadedPhotos.length > 0) {
          setCarouselImages(getCarouselMediaItems(uploadedPhotos));
      }
  }, [JSON.stringify(uploadedPhotos)]);

  const [carouselGifs, setCarouselGifs] = useState([] as any);

  useEffect(() => {
      if (uploadedGifs && uploadedGifs.length > 0) {
          setCarouselGifs(getCarouselMediaItems(uploadedGifs));
      }
  }, [JSON.stringify(uploadedGifs)]);

  const areMediaAttachmentsInProgress = (): boolean => {
      return (
          isUploadingInProgress.some((state: MediaUploadState) => state) ||
          (mediaAttachments &&
              mediaAttachments.some(
                  (media) => media.status === OrderMediaStatuses.IN_PROGRESS
              ))
      );
  };

  const downloadAllPhotos = (): void => {
      uploadedPhotos.reduce(async (r, x) => {
          await r;
          await downloadAttachment(orderId, x.id, x.url.substr(x.url.indexOf('_') + 1));
          return new Promise(resolve => setTimeout(resolve, 5000));
      }, Promise.resolve());
  }

  const downloadVideo = async () => {
      const url = uploadedVideo.url;
      const filename = url.substr(url.indexOf('_') + 1);
      downloadAttachment(orderId, uploadedVideo.id, filename);
  }

  const downloadAttachment = async (orderId: string, attachmentId: string, filename?: string) => {
      const res = await packageService.getDownloadUrl(orderId, attachmentId);
      if (res.status === ResponseStatuses.SUCCESS) {
            let element = document.createElement("a");
            element.href = res.data.temporaryDownloadUrl;
            element.download = filename || 'download';
            document.body.appendChild(element);
            element.click()
            document.body.removeChild(element);
        }
  }

  const setHttp = (link: string) => {

  if (link.search(/^http[s]?\:\/\//) === -1) {
    link = 'http://' + link;
  }

  return link;

  }

  const linkClicked = () => {
    window.location.href = setHttp(orderLink);
  }

  const handleApprove = () => {
    setApproved(true);
  }

  return (
        <>
            <div className={"thirdStepContent"}>
                <div className={"thirdStepHeader"}>
                    {setCurrent &&
                        <button className={"goBack"} onClick={() => setCurrent(isGoToRiddleTriggered ? 1 : 0)}>
                            <LeftOutlined/>
                            <span className={"text"}>Zpátky na editaci</span></button>
                    }
                </div>

                <div className={"mediaPreview"}>
                    {setCurrent &&
                        <h2>Přesně tohle uvidí obdarovaný, až si rozbalí svůj QR kód:</h2>
                    }
                    <h1>
                        Gratulujeme!<br/>
                        Někdo si dal na Tvém dárku opravdu záležet. Koukej, jaké překvapení Tvá #Gifitstory ukrývá:
                    </h1>
                    {(isVideoUploadTriggered ||
                        (isVideoUploaded && uploadedVideo && uploadedVideo.url) ||
                        (uploadedVideo &&
                            (uploadedVideo.url ||
                                uploadedVideo.status === OrderMediaStatuses.IN_PROGRESS))) && (
                        <div className={"videoWrapper mediaPreviewItem"}>
                            <div className={"video"}>
                                {(isVideoUploadTriggered && isUploadingInProgress[2]) ||
                                (uploadedVideo &&
                                    uploadedVideo.status === OrderMediaStatuses.IN_PROGRESS) ? (
                                    <FileUploadProgress uploadState={isUploadingInProgress[2]} mediaType={MediaTypes.VIDEO} noCancel={true} />
                                ) : uploadedVideo &&
                                uploadedVideo.status === OrderMediaStatuses.COMPLETED ? (
                                    uploadedVideo &&
                                    (uploadedVideo.url.includes("youtube") ? (
                                        <YoutubeEmbed url={uploadedVideo.url}/>
                                    ) : (
                                        <>
                                            <AzureMP
                                                skin="amp-flush"
                                                controls playsinline autoplay muted
                                                options={{controls: true, autoplay: true, muted: true, playsinline: true}}
                                                src={[
                                                    {
                                                        src: uploadedVideo.streamingUrl,
                                                        type: "application/vnd.ms-sstr+xml",
                                                    },
                                                ]}
                                            />
                                        </>
                                    ))
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            {!setCurrent && !uploadedVideo.url.includes("youtube") && (<div className={'downloadWrapper'}>
                              <label className="download" onClick={downloadVideo}>STÁHNOUT</label>
                            </div>)}
                        </div>
                    )}

                    {(isUploadingInProgress && isUploadingInProgress[1]
                            ? (
                            <div className={"loadingOverlay"}>
                                <LoadingOutlined/>
                            </div>
                        ) : (uploadedPhotos && uploadedPhotos.some(
                            (item) => item.status === OrderMediaStatuses.COMPLETED
                        )) ? (
                            <div className={"photosWrapper mediaPreviewItem"}>
                                <div className={"photos"}>
                                    <ImageGallery
                                        items={carouselImages}
                                        showFullscreenButton={false}
                                        showPlayButton={false}
                                    />
                                </div>
                                {!setCurrent && (<div className={'downloadWrapper'}>
                                    <label className="download" onClick={() => downloadAllPhotos()}>STÁHNOUT</label>
                                </div>)}
                            </div>
                        ) : (
                            <div></div>
                        ))}

                    {orderRichText && orderRichText[0] && orderRichText[0].value ? (
                      <div className={"thirdStepHeader"}>
                        <div className={"mainText"}>
                          <Markup
                            content={
                              orderRichText && orderRichText[0] && orderRichText[0].value
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}

                    {(isUploadingInProgress && isUploadingInProgress[3] ? (
                            <div className={"loadingOverlay"}>
                                <LoadingOutlined/>
                            </div>
                        ) : (uploadedGifs && uploadedGifs.some(
                            (item) => item.status === OrderMediaStatuses.COMPLETED
                        )) ? (
                            <div className={"gifsWrapper mediaPreviewItem"}>
                                <div className={"gifs"}>
                                    <ImageGallery
                                        items={carouselGifs}
                                        showFullscreenButton={false}
                                        showPlayButton={false}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        ))}
                  {!setCurrent && orderLink && (
                    <div className="save" >
                      <button onClick={() => linkClicked()}>
                        Zobrazit příběh boxu
                      </button>
                    </div>
                  )}
                </div>

                {/* <div className={"riddleWrapper"}>
          <h1>Kod pro vaš box je</h1>
          <div className={"code"}>
            <p>{orderCode}</p>
          </div> */}

                {/* {setCurrent ? <div className={"code"}>
                        <p>{orderCode}</p>
                    </div>
                    : !isRiddleUploadingInProgress && <>
                    {!isAnswersEqual && orderRiddle ?
                        <>
                            <div className={"riddleForm"}
                                 style={{border: (isSubmitTriggered && !isAnswersEqual) ? '2px solid red' : 'none'}}>
                                <h2 className={"riddleFormHeader"}>{orderRiddle.question}</h2>

                                <Formik
                                    validationSchema={validationSchema}
                                    initialValues={{answer: ""}}
                                    onSubmit={(values) => {
                                        setIsSubmitTriggered(true)

                                        if (values.answer === orderRiddle.answer) {
                                            setIsAnswersEqual(true)
                                        }
                                    }}
                                >
                                    {({errors, touched}) => (
                                        <Form>
                                            <FormikField
                                                name="answer"
                                                render={({field}: any) =>
                                                    <Input
                                                        style={errors.answer && touched.answer && {border: '1px solid red'}}
                                                        {...field}
                                                        placeholder="Nevím, co s tím."/>
                                                }
                                            />

                                            <Button type="primary" htmlType="submit"><CheckOutlined/></Button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <p className={"errorText"}
                               style={{opacity: (isSubmitTriggered && !isAnswersEqual) ? '1' : '0'}}>Zkuste to ještě
                                jednou</p>
                        </>
                        : <div className={"code"}>
                            <p>{orderCode}</p>
                        </div>
                    }
                </>
                }
                */}
                {/* </div> */}

        {(setCurrent != null && !areMediaAttachmentsInProgress()) && (
          <div className={"save"}>
            {approved ? (
              <Link to={"/success"}>
                <button>
                  DOKONČIT A ULOŽIT <RightOutlined />
                </button>
              </Link>
            ) : (
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "20px", flexDirection: "column", padding: "20px"}}>
                <p style={{color: "#B26080"}}>
                Naposledy si své přání pořádně zkontroluj. Potřebuješ něco upravit? Ještě se můžeš <a className={"text"} onClick={() => setCurrent(isGoToRiddleTriggered ? 1 : 0)}><span><em>vrátit zpátky k editaci</em></span></a>. Je všechno podle tvých představ? Stačí poslední kliknutí a máš hotovo.
                </p>

                <button onClick={() => {handleApprove()}} style={{background: "#E49FAD ", color: "white", margin: "20px 0 0 0"}}>
                DOKONČIT PŘÁNÍ <RightOutlined />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
