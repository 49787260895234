import React, {useEffect, useState} from 'react';
import styles from "./CameraOpen.module.scss";
import Webcam from "react-webcam";
import {CloseOutlined, LoadingOutlined, RetweetOutlined, SaveOutlined, VideoCameraOutlined} from "@ant-design/icons";
import {Button} from "antd";
import Timer from "react-compound-timer";
import { AppMimeType } from '../../../../helpers/AppMimeType'

type VideoProps = {
    isVideoCancelTriggered: boolean;
    setIsVideoCancelTriggered: (isVideoCancelTriggered: boolean) => void;
    isTakeVideoModalVisible: boolean;
    setIsTakeVideoModalVisible: (isVisible: boolean) => void;
    onDrop: (files: File[]) => void;
    videoConstraints: any;
}

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

export const Video: React.FC<VideoProps> = ({
                                                videoConstraints,
                                                setIsVideoCancelTriggered,
                                                isVideoCancelTriggered,
                                                setIsTakeVideoModalVisible,
                                                isTakeVideoModalVisible,
                                                onDrop
                                            }) => {

    const webcamRef: any = React.useRef(null);
    const mediaRecorderRef: any = React.useRef(null);
    const [capturing, setCapturing] = React.useState(false);
    const [recordedChunks, setRecordedChunks] = React.useState([]);
    const [devices, setDevices] = React.useState([] as MediaDeviceInfo[]);

    const handleStartCaptureClick = React.useCallback(() => {
        setCapturing(true);
        if(MediaRecorder.isTypeSupported(AppMimeType.WEBM)){
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
                mimeType: AppMimeType.WEBM
            });
        } else {
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
                mimeType: AppMimeType.MP4
            });
        }
        
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = React.useCallback(
        ({data}) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStopCaptureClick = React.useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
    }, [mediaRecorderRef, webcamRef, setCapturing]);


    useEffect(() => {
        if (!isVideoCancelTriggered) {
            if (recordedChunks.length > 0) {
                onDrop([recordedChunks[recordedChunks.length - 1]])
            }
        }
    }, [recordedChunks])


    const [isReady, setIsReady] = useState(false)


    const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

    const handleSwitchCameraClick = React.useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);


    const onWebcamUserMedia = () => {
        navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
            setDevices(mediaDevices.filter(({ kind }: any) => kind === "videoinput"));
            setIsReady(true);
        });
    }


    let maxDuration = 0
    if (process.env.REACT_APP_RECORDER_VIDEO_MAX_DURATION) {
        maxDuration = +process.env.REACT_APP_RECORDER_VIDEO_MAX_DURATION
    }

    return <>
        <div className={styles.overlay} style={{display: isTakeVideoModalVisible ? 'flex' : 'none'}}>
            <div className={styles.webcamWrapper}
                 style={{textAlign: 'center', display: isTakeVideoModalVisible ? 'block' : 'none'}}>
                {!isReady && <div className={styles.loader}><LoadingOutlined/></div>}

                {isTakeVideoModalVisible &&
                <Webcam
                    onUserMedia={onWebcamUserMedia}
                    audio={true}
                    ref={webcamRef}
                    videoConstraints={{
                        ...videoConstraints,
                        facingMode,
                        width: 1280,
                        height: 720
                    }}
                    audioConstraints={{
                        "sampleSize": 16,
                        "channelCount": 2,
                        "echoCancellation": true
                    }}
                />
                }
                <div className={styles.controls}>
                    <Button type={"primary"} onClick={() => {
                        setIsVideoCancelTriggered(true)
                        setIsTakeVideoModalVisible(false)
                        capturing && handleStopCaptureClick()
                        setIsReady(false)
                    }}><CloseOutlined/></Button>

                    {(devices.length > 1 && !capturing) &&
                    <Button type={"primary"} disabled={!isReady} onClick={() => {
                        handleSwitchCameraClick()
                    }}><RetweetOutlined/></Button>
                    }

                    {capturing ? <>
                        <div className={styles.recordingIndicator}>
                            <div className={styles.circle}/>
                        </div>

                        <Timer checkpoints={[
                            {
                                time: maxDuration,
                                callback: () => {
                                    handleStopCaptureClick()
                                    setIsTakeVideoModalVisible(false)
                                    setIsReady(false)
                                },
                            }
                        ]}>
                            <Timer.Hours formatValue={value => `${value < 10 ? `0${value}` : value}:`} />
                            <Timer.Minutes formatValue={value => `${value < 10 ? `0${value}` : value}:`} />
                            <Timer.Seconds formatValue={value => `${value < 10 ? `0${value}` : value}`} />
                        </Timer>

                        <Button type={"primary"} disabled={!isReady}
                                onClick={() => {
                                    handleStopCaptureClick()
                                    setIsTakeVideoModalVisible(false)
                                    setIsReady(false)
                                }}><SaveOutlined/></Button>
                    </> : (
                        <Button disabled={!isReady} type={"primary"}
                                onClick={() => {
                                    handleStartCaptureClick()
                                }}
                        ><VideoCameraOutlined/></Button>
                    )}

                </div>
            </div>
        </div>
    </>
};