import {Action, applyMiddleware, combineReducers, compose, createStore} from "redux"
import thunkMiddleware, {ThunkAction} from "redux-thunk"
import {authReducer} from "./reducers/admin/authReducer";
import {loaderReducer} from "./reducers/loaderReducer";
import {adminPanelReducer} from "./reducers/admin/adminPanelReducer";
import {packageReducer} from "./reducers/user/packageReducer";
import {riddlesReducer} from "./reducers/user/riddlesReducer";
import {FAQReducer} from "./reducers/user/FAQReducer";

let rootReducer = combineReducers({
    auth: authReducer,
    loader: loaderReducer,
    adminPanelData: adminPanelReducer,
    packageInfo: packageReducer,
    riddleInfo: riddlesReducer,
    FAQ: FAQReducer
});

type RootReducerType = typeof rootReducer
export type AppStateType = ReturnType<RootReducerType>
export type DispatchType = typeof store.dispatch

export type InferActionsType<T> = T extends { [keys: string]: (...args: any[]) => infer U } ? U : never

export type BaseThunkType<A extends Action, R = Promise<void>> = ThunkAction<R, AppStateType, unknown, A>

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)))

export default store