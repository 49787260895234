import React, {useEffect, useState} from 'react';
import styles from "./AdminHeader.module.scss";
import logoText from '../../../assets/images/logo.png'
import {Link} from "react-router-dom";
import cn from "classnames"
import {SettingOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {History} from "history";
import {useHistory} from "react-router";
import {Menu, Dropdown} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import {logoutUser} from "../../../store/reducers/admin/authReducer";
import {useDispatch} from "react-redux";

enum AdminPages {
    ORDERS = 'orders',
    SETTINGS = 'settings'
}


export const AdminHeader = () => {

    const dispatch = useDispatch()

    const [activePath, setActivePath] = useState('')

    const history: History = useHistory()
    useEffect(() => {
        setActivePath(history.location.pathname.split('/')[2])
    }, [history]);

    const username = localStorage.getItem('username') || sessionStorage.getItem('username')

    const menu = (
                <button className={styles.logoutBtn} onClick={() => dispatch(logoutUser())}>
                    Odhlásit se
                </button>
    );

    return <>
        <div className={styles.contentWrapper}>
            <div className={styles.adminHeader}>
                <div className={styles.logo}>
                    <Link to={'/admin'}>
                        <img src={logoText} alt="logo"/>
                    </Link>
                </div>

                <div className={styles.buttons}>
                    <Link to={`/admin/${AdminPages.ORDERS}`}>
                        <button onClick={() => setActivePath(AdminPages.ORDERS)}
                                className={cn(styles.adminPanelBtn, activePath === AdminPages.ORDERS && styles.active)}>
                            <UnorderedListOutlined/>
                            <p>Vzkazy</p>
                        </button>
                    </Link>


                    {/* 
                    <Link to={`/admin/${AdminPages.SETTINGS}`}>
                        <button onClick={() => setActivePath(AdminPages.SETTINGS)}
                                className={cn(styles.adminPanelBtn, activePath === AdminPages.SETTINGS && styles.active)}>
                            <SettingOutlined/>
                            <p>Nastavení</p>
                        </button>
                    </Link> 
                    */}


                </div>

                <div className={styles.userInfo}>
                    <Dropdown overlay={menu}>
                        <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <div className={styles.antDropdownContent}>
                                <div>
                                    <div className={styles.username}><p>{username}</p></div>
                                    <div className={styles.role}><p>Administrace</p></div>
                                </div>
                                <div className={styles.downOutlinedWrapper}>
                                    <DownOutlined/>
                                </div>
                            </div>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    </>
};