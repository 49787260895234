import React, {useEffect, useState} from 'react';
import styles from "../../../pages/admin/Orders/Orders.module.scss";
import cn from "classnames";
import {MoreOutlined} from "@ant-design/icons";
import {changeStatus, deleteOrderById} from "../../../store/reducers/admin/adminPanelReducer";
import {useDispatch, useSelector} from "react-redux";
import {IsModalVisibleItem, OrderStatuses, TableColumnItemProps} from "../../../utils/adminTable";


type MoreActionsProps = {
    isMoreActionsModalVisible: IsModalVisibleItem;
    setIsMoreActionsModalVisible: (obj: IsModalVisibleItem) => void;
    isConfirmModalVisible: IsModalVisibleItem;
    setIsConfirmModalVisible: (obj: IsModalVisibleItem) => void;
}

export const MoreActions: React.FC<TableColumnItemProps & MoreActionsProps> = ({
                                                                                   order,
                                                                                   isMoreActionsModalVisible,
                                                                                   setIsMoreActionsModalVisible,
                                                                                   isConfirmModalVisible,
                                                                                   setIsConfirmModalVisible
                                                                               }) => {

    const [status, setStatus] = useState("");
    const dispatch = useDispatch();



    const deleteOrder = (orderId: string, setIsMoreActionsModalVisible: any, setIsConfirmModalVisible: any) => {
        dispatch(deleteOrderById(orderId, setIsMoreActionsModalVisible, setIsConfirmModalVisible))
    }

    const changeOrderStatus = (orderId: string, status : string) => {
        dispatch(changeStatus(orderId, status, setIsConfirmModalVisible))
    }

    const changeOrderStatusBtns = [
        {status: "", displayName: "Smazat"},
        {status: "LAUNCHED", displayName: "Vytvořit"},
        {status: "INPROGRESS", displayName: "Rozpracovat"},
        {status: "SAVED", displayName: "Uložit"},
    ]

    const openModal = () => {
        return(
            <div className={cn(styles.adminActionModal, styles.confirmDeleteModal)}
                style={isConfirmModalVisible.id && isConfirmModalVisible.id === order.id ? {display: 'block'} : {display: 'none'}}>
                <div className={styles.confirmActionModalWrapper}>
                    {
                        status !== ''
                        ? (<>
                            <p>Jste si jistí, že to chcete změnit?</p> 

                            <div className={styles.confirmActionModalBtns}>
                                <button className={styles.confirm} onClick={() => {
                                    changeOrderStatus(order.id, status)
                                    }}>Ano
                                </button>
                                <button className={styles.decline} onClick={() => {
                                    setIsMoreActionsModalVisible({})
                                    setIsConfirmModalVisible({})
                                    }}>Ne
                                </button>
                            </div>
                            </>)

                        : (<>
                            <p>Jste si jistí, že to chcete smazat?</p>
                            
                            <div className={styles.confirmActionModalBtns}>
                                <button className={styles.confirm} onClick={() => {
                                    deleteOrder(order.id, setIsMoreActionsModalVisible, setIsConfirmModalVisible)
                                    }}>Ano
                                </button>
                            
                                <button className={styles.decline} onClick={() => {
                                    setIsMoreActionsModalVisible({})
                                    setIsConfirmModalVisible({})
                                    }}>Ne
                                </button>
                            </div>
                        </>)
                    }
                </div>
            </div>
        );
    }

    return (
        <div>
            <div
                onMouseLeave={() => {
                    setIsMoreActionsModalVisible({})
                    setIsConfirmModalVisible({})
                }}
                className={styles.moreActionsWrapper}>
                <button onMouseEnter={() => setIsMoreActionsModalVisible({id: order.id, isVisible: true})}
                        className={cn(styles.adminTableBtn, styles.dots)}><MoreOutlined/></button>

                <div onMouseLeave={() => {
                    setIsMoreActionsModalVisible({})
                    setIsConfirmModalVisible({})
                }}>
                
                    <div className={cn(styles.adminActionModal, styles.moreActionsModal)}
                         style={isMoreActionsModalVisible.id && isMoreActionsModalVisible.id === order.id ? {display: 'flex', flexWrap: 'wrap'} : {display: 'none'}}>

                        {/* <button onClick={() => setIsConfirmDeleteModalVisible({id: order.id, isVisible: true})}
                                className={styles.deleteBtn}>Smazat
                        </button> */}
                        {
                            changeOrderStatusBtns.map((e, i) => (
                                <button className={cn(styles.moreActionsModal__mKT3q_button)} onClick={() => { 
                                    setIsConfirmModalVisible({id: order.id, isVisible: true})
                                    setStatus(e.status)
                                 }} key={i}>{e.displayName}</button>
                            ))
                        }
                    </div>

                    {openModal()}
                    {/* <div className={cn(styles.adminActionModal, styles.confirmDeleteModal)}
                         style={isConfirmDeleteModalVisible.id && isConfirmDeleteModalVisible.id === order.id ? {display: 'block'} : {display: 'none'}}>
                        <div className={styles.confirmActionModalWrapper}>
                            <p>Jste si jistí, že to chcete smazat?</p>
                            <div className={styles.confirmActionModalBtns}>
                                <button className={styles.confirm} onClick={() => {
                                    deleteOrder(order.id, setIsMoreActionsModalVisible, setIsConfirmDeleteModalVisible)
                                }}>Ano
                                </button>
                                <button className={styles.decline} onClick={() => {
                                    setIsMoreActionsModalVisible({})
                                    setIsConfirmDeleteModalVisible({})
                                }}>Ne
                                </button>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </div>
    );
};

