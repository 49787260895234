import React, {useEffect} from 'react';
import "./PackageInfo.scss"
import {useHistory} from "react-router";
import {History} from "history";
import {useDispatch} from "react-redux";
import {getOrderInfo} from "../../../store/reducers/user/packageReducer";
import {ThirdStep} from "../../../components/user/CreatePackage/ThirdStep/ThirdStep";

export const PackageInfo = () => {

    const history: History = useHistory();
    const dispatch = useDispatch();

    const orderId = history.location.pathname.split("/")[2];

    useEffect(() => {
        if (orderId) {
          dispatch(getOrderInfo('', orderId));
        }
    }, [orderId]);

    return <div className={"packageInfoWrapper"}>
        <ThirdStep />
    </div>
};
