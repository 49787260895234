import {NotificationError} from "../../../utils/notifications";
import {ResponseStatuses} from "../../../api/axiosInstance";
import {BaseThunkType, InferActionsType} from "../../store";
import {loaderActions} from "../loaderReducer";
import {riddleService} from "../../../api/riddlesService";
import {SET_IS_RIDDLE_ADDED, SET_IS_RIDDLE_UPLOADING_IN_PROGRESS, SET_PREDEFINED_RIDDLE} from "../../types";
import {getOrderInfo} from "./packageReducer";
import {RiddleType} from "../../../models/Package";


let initialState = {
    isRiddleAdded: false,
    isRiddleUploadingInProgress: false,
    predefinedRiddles: [] as RiddleType[]
};


export const riddlesReducer = (state = initialState, action: ActionsTypes): InitialStateType => {
    switch (action.type) {
        case SET_IS_RIDDLE_ADDED:
            return {
                ...state,
                isRiddleAdded: true
            }
        case SET_IS_RIDDLE_UPLOADING_IN_PROGRESS:
            return {
                ...state,
                isRiddleUploadingInProgress: action.payload.isInProgress
            }
        case SET_PREDEFINED_RIDDLE:
            return {
                ...state,
                predefinedRiddles: action.payload.data
            }
        default:
            return state;
    }
}


export const riddlesActions = {
    setIsRiddleAdded: () => ({
        type: SET_IS_RIDDLE_ADDED,
    } as const),
    setIsRiddleUploadingInProgress: (isInProgress: boolean) => ({
        type: SET_IS_RIDDLE_UPLOADING_IN_PROGRESS,
        payload: {isInProgress}
    } as const),
    setPredefinedRiddles: (data: RiddleType[]) => ({
        type: SET_PREDEFINED_RIDDLE,
        payload: {data}
    } as const),
}

export const addCustomRiddle = (setIsRiddleSaved: (isSaved: boolean) => void,
                                orderId: string, question: string, answer: string, setCurrent: (current: number) => void,
                                ): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(true))
            dispatch(riddlesActions.setIsRiddleUploadingInProgress(true))


            const data = await riddleService.addRiddle(orderId, question, answer);

            if (data.status === ResponseStatuses.SUCCESS) {
                setIsRiddleSaved(true)
                setCurrent(2)
                dispatch(riddlesActions.setIsRiddleAdded())
                await dispatch(getOrderInfo('', orderId))
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(false))
            dispatch(riddlesActions.setIsRiddleUploadingInProgress(false))
        }
    }

    export const addPredefinedRiddle = (orderId: string, riddleId: string, setCurrent: (current: number) => void): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(true))
            dispatch(riddlesActions.setIsRiddleUploadingInProgress(true))


            const data = await riddleService.addPredefinedRiddleToOrder(orderId, riddleId);

            if (data.status === ResponseStatuses.SUCCESS) {
                setCurrent(2)
                dispatch(riddlesActions.setIsRiddleAdded())
                await dispatch(getOrderInfo('', orderId))
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(false))
            dispatch(riddlesActions.setIsRiddleUploadingInProgress(false))
        }
    }

    export const getPredefined = (): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(true))

            const data = await riddleService.getPredefinedRiddles();

            if (data.status === ResponseStatuses.SUCCESS) {
                dispatch(riddlesActions.setPredefinedRiddles(data.data))
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(false))
            dispatch(riddlesActions.setIsRiddleUploadingInProgress(false))
        }
    }

    export const deleteOrderRiddle = (orderId: string, riddleId: string, setCurrent: (current: number) => void): ThunkType =>
    async (dispatch) => {
        try {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(true))

            const data = await riddleService.deleteRiddle(orderId, riddleId);

            if (data.status === ResponseStatuses.SUCCESS) {
                await dispatch(getOrderInfo('', orderId))
                setCurrent(2)
            }

        } catch (error) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(false))
            dispatch(riddlesActions.setIsRiddleUploadingInProgress(false))
        }
    }


type ActionsTypes = InferActionsType<typeof riddlesActions>
export type InitialStateType = typeof initialState
export type ThunkType = BaseThunkType<ActionsTypes>