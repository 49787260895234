import {SavedMediaAttachmentType} from "../models/Package";

export const getCarouselMediaItems = (uploadedItems: SavedMediaAttachmentType[]) => {
    return uploadedItems.map((item) => {
        return {
            original: item.url,
            thumbnail: item.url,
        }
    })
}
