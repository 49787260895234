import React, {useEffect} from 'react';
import {HowItWork} from "../../../components/user/HomePage/HowItWork/HowItWork";
import {AlmostDone} from "../../../components/user/HomePage/AlmostDone/AlmostDone";
import {WriteToUs} from "../../../components/user/HomePage/WriteToUs/WriteToUs";
import {useHistory} from "react-router";
import {getOrderInfo} from "../../../store/reducers/user/packageReducer";
import {useDispatch, useSelector} from "react-redux";
import {getOrderIdSelector} from "../../../store/selectors/packageSelectors";
import {History} from "history";

export const HomePage = () => {
    const dispatch = useDispatch()

    const history: History = useHistory()

    const orderNumber = history.location.search.split('=')[1]

    useEffect(() => {
        sessionStorage.setItem('currentStep', '0')
    }, [])

    useEffect(() => {
        if (orderNumber) {
            dispatch(getOrderInfo(orderNumber))
        }
    }, [orderNumber]);

    const orderId = useSelector(getOrderIdSelector)

    useEffect(() => {
        if (orderId) {
            sessionStorage.setItem('orderId', orderId)
        }
    }, [orderId]);


    return <>
        <AlmostDone orderId={sessionStorage.getItem('orderId') || orderId}/>
        {/*<HowItWork orderId={sessionStorage.getItem('orderId') || orderId}/>
        <WriteToUs/>*/}
    </>
};