import {NotificationError} from "../../../utils/notifications";
import {ResponseStatuses} from "../../../api/axiosInstance";
import {BaseThunkType, InferActionsType} from "../../store";
import {packageService} from "../../../api/packageService";
import {
    DELETE_ATTACHMENT,
    RESET_IS_UPLOADING_IN_PROGRESS,
    SET_IS_UPLOADING_IN_PROGRESS,
    SET_IS_VIDEO_UPLOADED,
    SET_ORDER_INFO,
    DELETE_RICH_TEXT,
    ADD_UPLOAD_PROGRESS,
    SET_ORDER_MEDIA_ATTACHMENT,
    SET_MEDIA_ATTACHMENT_PROCESSING_STATE_POLL
} from "../../types";
import { loaderActions } from "../loaderReducer";

import {AttachmentTypes} from "../../../components/user/CreatePackage/FirstStep/FirstStep";
import {OrderMediaStatuses, PackageType, SavedMediaAttachmentType} from "../../../models/Package";
import {MediaTypes} from "../../../helpers/MediaInfo";
import axios, { CancelTokenSource } from "axios";

type MediaUploadState = false | {
    progress: number,
    progressHandle?: NodeJS.Timer,
    cancelToken?: CancelTokenSource
}

const MediaUploadingProgressKeys = {
    [MediaTypes.TEXT]: 0,
    [MediaTypes.PHOTO]: 1,
    [MediaTypes.VIDEO]: 2,
    [MediaTypes.GIF]: 3
}

export type InitialStateType = {
    orderInfo: PackageType,
    isVideoUploaded: boolean,
    isMediaUploadingInProgress: MediaUploadState[],
    mediaAttachmentProcessingStatePoll: NodeJS.Timer | null
}

let initialState: InitialStateType = {
    orderInfo: {} as PackageType,
    isVideoUploaded: false,
    isMediaUploadingInProgress: [],
    mediaAttachmentProcessingStatePoll: null
}

const USER_CANCELLED = "user_cancelled";

export const packageReducer = (state = initialState, action: ActionsTypes): InitialStateType => {
    switch (action.type) {
        case SET_ORDER_INFO:
            return {
                ...state,
                orderInfo: action.payload.data
            }
        case DELETE_ATTACHMENT:

            const newMediaAttachments = state.orderInfo.mediaAttachments.filter((item) => {
                return item.id !== action.payload.attachmentId
            })

            return {
                ...state,
                orderInfo: {
                    ...state.orderInfo,
                    mediaAttachments: newMediaAttachments

                }
            }
        case DELETE_RICH_TEXT:

            const newRichTextAttachments = state.orderInfo.richTextAttachments.filter((item) => {
                return item.id !== action.payload.richTextAttachmentId
            })

            return {
                ...state,
                orderInfo: {
                    ...state.orderInfo,
                    richTextAttachments: newRichTextAttachments
                }
            }
        case SET_IS_VIDEO_UPLOADED:
            return {
                ...state,
                isVideoUploaded: action.payload.isUploaded
            }
        case RESET_IS_UPLOADING_IN_PROGRESS:
            return {
                ...state,
                isMediaUploadingInProgress: []
            }

        case SET_IS_UPLOADING_IN_PROGRESS:
            return (() => {
                const arr = [...state.isMediaUploadingInProgress];
                const key = MediaUploadingProgressKeys[action.payload.mediaType];
                const oldState = arr[key];
                const newState = action.payload.uploadState;

                // clear faux progress timer
                if (oldState && oldState.progressHandle &&
                    (newState === false || newState.progressHandle !== oldState.progressHandle)) {
                    clearInterval(oldState.progressHandle);
                }

                arr[key] = newState;

                return {
                    ...state,
                    isMediaUploadingInProgress: arr
                }
            })();

        case ADD_UPLOAD_PROGRESS:
            return (() => {
                const arr = [...state.isMediaUploadingInProgress];
                const key = MediaUploadingProgressKeys[action.payload.mediaType];
                const uploadState = arr[key]

                if (uploadState !== false) {
                    uploadState.progress = Math.min(100, uploadState.progress + action.payload.progress);
                }
                arr[key] = uploadState;

                return {
                    ...state,
                    isMediaUploadingInProgress: arr
                }
            })();

        case SET_ORDER_MEDIA_ATTACHMENT:
            const mediaAttachment = action.payload.mediaAttachment;

            return {
                ...state,
                orderInfo: {
                    ...state.orderInfo,
                    mediaAttachments: state.orderInfo.mediaAttachments.find(x => x.id === mediaAttachment.id)
                        ? state.orderInfo.mediaAttachments.map(x => x.id === mediaAttachment.id ? mediaAttachment : x)
                        : state.orderInfo.mediaAttachments.concat(mediaAttachment)
                }
            }

        case SET_MEDIA_ATTACHMENT_PROCESSING_STATE_POLL:
            if (state.mediaAttachmentProcessingStatePoll !== null) {
                clearInterval(state.mediaAttachmentProcessingStatePoll);
            }
            return {
                ...state,
                mediaAttachmentProcessingStatePoll: action.payload.mediaAttachmentProcessingStatePoll
            }

        default:
            return state;
    }
}


export const packageActions = {
    setOrderInfo: (data: any) => ({
        type: SET_ORDER_INFO,
        payload: {data}
    } as const),
    deleteAttachment: (attachmentId: string) => ({
        type: DELETE_ATTACHMENT,
        payload: {attachmentId}
    } as const),
    deleteRichText: (richTextAttachmentId: string) => ({
        type: DELETE_RICH_TEXT,
        payload: {richTextAttachmentId}
    } as const),
    setIsVideoUploaded: (isUploaded: boolean) => ({
        type: SET_IS_VIDEO_UPLOADED,
        payload: {isUploaded}
    } as const),
    setIsUploadingInProgress: (mediaType: MediaTypes, uploadState: MediaUploadState) => ({
        type: SET_IS_UPLOADING_IN_PROGRESS,
        payload: {mediaType, uploadState}
    } as const),
    resetIsUploadingInProgress: () => ({
        type: RESET_IS_UPLOADING_IN_PROGRESS,
        payload: {}
    } as const),
    addUploadProgress: (mediaType: MediaTypes, progress: number) => ({
        type: ADD_UPLOAD_PROGRESS,
        payload: {mediaType, progress}
    } as const),
    setOrderMediaAttachment: (mediaAttachment: SavedMediaAttachmentType) => ({
        type: SET_ORDER_MEDIA_ATTACHMENT,
        payload: { mediaAttachment }
    } as const),
    setMediaAttachmentProcessingStatePoll: (mediaAttachmentProcessingStatePoll: NodeJS.Timer | null) => ({
        type: SET_MEDIA_ATTACHMENT_PROCESSING_STATE_POLL,
        payload: { mediaAttachmentProcessingStatePoll }
    } as const)
}

/* THUNKS */

export const getOrderInfo = (orderNumber: string = '', orderId: string = ''): ThunkType =>
    async (dispatch) => {

        try {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(true))

            let data: any = {}
            if (orderNumber) {
                data = await packageService.getOrderInfoByNumber(orderNumber);
            } else if (orderId) {
                data = await packageService.getOrderInfoById(orderId);
            }

            if (data.status === ResponseStatuses.SUCCESS) {
                dispatch(packageActions.setOrderInfo(data.data));
            }

        } catch (error: any) {
            NotificationError('Chyba', error.data ? (error.data.message || error.data.title) : "Nebylo nalezeno číslo objednávky")
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(false))
        }
    }


export const uploadAttachment = (mediaType: MediaTypes,
                                 orderId: string,
                                 payload: File | null | string, text: string, attachmentType: AttachmentTypes, triggeredFrom: 'QR' | 'Button'): ThunkType =>
    async (dispatch) => {

        try {

            let data = {} as any

            if (attachmentType === AttachmentTypes.MEDIA) {
                const cancelToken = axios.CancelToken.source();
                const progressHandle = startProgressTimer(mediaType, (payload as File).size, dispatch);
                dispatch(packageActions.setIsUploadingInProgress(mediaType, { progress: 0, progressHandle, cancelToken }))
                data = await packageService.sendMediaAttachment(mediaType, orderId, payload as File, cancelToken);
            } else if (attachmentType === AttachmentTypes.RICH_TEXT) {
                dispatch(packageActions.setIsUploadingInProgress(mediaType, { progress: 0 }))
                data = await packageService.sendRichTextAttachment(orderId, text);
            } else if (attachmentType === AttachmentTypes.YOUTUBE_LINK) {
                dispatch(packageActions.setIsUploadingInProgress(mediaType, { progress: 0 }))
                data = await packageService.sendYoutubeLink(orderId, payload as string)
            }

            if (data.status === ResponseStatuses.ACCEPTED || data.status === ResponseStatuses.SUCCESS) {
                if (data.data.number) {
                    dispatch(packageActions.setOrderInfo(data.data))
                } else {
                    dispatch(packageActions.setOrderMediaAttachment(data.data))
                }

                if (mediaType === MediaTypes.VIDEO) {
                    dispatch(packageActions.setIsVideoUploaded(true))
                }

                if (data.data.status !== OrderMediaStatuses.IN_PROGRESS) {
                    dispatch(packageActions.setIsUploadingInProgress(mediaType, false))
                } else {
                    dispatch(startMediaAttachmentStatusPolling(orderId, mediaType));
                }
                //                dispatch(getOrderInfo('', orderId));
            } else {
                dispatch(packageActions.setIsUploadingInProgress(mediaType, false))
            }

        } catch (error: any) {
            if (error.message !== USER_CANCELLED) {
                NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Prosím, zkuste to ještě jednou')
            }
            dispatch(packageActions.setIsUploadingInProgress(mediaType, false))
        }
    }

export const deleteAttachment = (orderId: string, attachmentId: string, attachmentType: AttachmentTypes, mediaType: MediaTypes): ThunkType =>
    async (dispatch) => {

        try {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(true))

            let data = {} as any

            if (attachmentType === AttachmentTypes.MEDIA || attachmentType === AttachmentTypes.YOUTUBE_LINK) {
                if(mediaType === MediaTypes.VIDEO){
                    dispatch(packageActions.setIsVideoUploaded(false))
                }
                data = await packageService.deleteMediaAttachment(orderId, attachmentId);
            } else if (attachmentType === AttachmentTypes.RICH_TEXT) {
                data = await packageService.deleteRichTextAttachment(orderId, attachmentId);
            }

            if (data.status === ResponseStatuses.SUCCESS){
                if(attachmentType === AttachmentTypes.MEDIA || attachmentType === AttachmentTypes.YOUTUBE_LINK){
                    dispatch(packageActions.deleteAttachment(attachmentId))
                } else {
                    dispatch(packageActions.deleteRichText(attachmentId))
                }
            }

            dispatch(packageActions.setIsUploadingInProgress(mediaType, false));

        } catch (error: any) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(false))
        }
    }

export const saveOrder = (orderId: string): ThunkType =>
    async (dispatch) => {

        try {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(true))

            const data = await packageService.saveOrder(orderId);

        } catch (error: any) {
            NotificationError(error.data ? error.data.status : 'Chyba', error.data ? error.data.message : 'Vyskytla se chyba')
        } finally {
            // @ts-ignore
            dispatch(loaderActions.handleLoader(false))
        }
    }

export const cancelUpload = (mediaType: MediaTypes, cancelToken: CancelTokenSource, attachment?: SavedMediaAttachmentType ): ThunkType =>
    async (dispatch) => {
        cancelToken.cancel(USER_CANCELLED);
        dispatch(packageActions.setMediaAttachmentProcessingStatePoll(null));
        if (attachment) {
            dispatch(deleteAttachment(attachment.orderId, attachment.id, AttachmentTypes.MEDIA, attachment.type))
        }

    }

export const startMediaAttachmentStatusPolling = (orderId: string, mediaType: MediaTypes): ThunkType =>
    async (dispatch, getState) => {
        if (getState().packageInfo.mediaAttachmentProcessingStatePoll === null) {
            const handler = async () => {
                const data = await packageService.getOrderInfoById(orderId);

                if (data.status === ResponseStatuses.SUCCESS) {
                    dispatch(packageActions.setOrderInfo(data.data));
                }

                const mediaAttachments = data.data.mediaAttachments;

                if (!mediaAttachments || mediaAttachments.length === 0 ||
                    mediaAttachments.every((x: SavedMediaAttachmentType) => x.status !== OrderMediaStatuses.IN_PROGRESS)) {
                    dispatch(packageActions.setMediaAttachmentProcessingStatePoll(null));
                    dispatch(packageActions.setIsUploadingInProgress(mediaType, false));
                }
            }

            const pollingHandle = setInterval(handler, 2000);
            dispatch(packageActions.setMediaAttachmentProcessingStatePoll(pollingHandle));
        }
    }


/* UTIL */

const startProgressTimer = (mediaType: MediaTypes, fileSize: number, dispatch: any): NodeJS.Timeout => {
    const flatDelay = 50000; // ms
    const ratePerSec = 100000; // byte/s
    const ticksPerSec = 4;
    const rate = ratePerSec / ticksPerSec;
    const ticks = (fileSize / rate) + (flatDelay * ticksPerSec / 1000);
    const progressPerTick = 100 / ticks;
    const interval = Math.round(1000 / ticksPerSec);
    const handler = () => {
        dispatch(packageActions.addUploadProgress(mediaType, progressPerTick));
    }
    return setInterval(handler, interval);
}

export type { MediaUploadState };
type ActionsTypes = InferActionsType<typeof packageActions>
export type ThunkType = BaseThunkType<ActionsTypes>
