export const newRiddleToolbar = {
  options: []
}

export const toolbar = {
  options: ['inline', 'link', 'fontSize', 'colorPicker', 'emoji'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['italic'],
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered'],
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right']
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_self',
    options: ['link'],
    linkCallback: undefined
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96], 
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  colorPicker: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
  },
  emoji: {
    inDropdown: true,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
  },
}
